import React from 'react';
import TkMeter from '../assets/images/camera.svg';
class User_UAN_No extends React.Component{
    render(){
        return(<div id="ltUANNo" className="modal fade">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className="card-title">UAN No.</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="POST" className="form-group" id="frmUANNo" name="frmUANNo" enctype="multipart/form-data">
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="createAppUser" />
                        <input type="hidden" name="m_type" id="m_type" value="createAppUser" />
                        <input type="hidden" name="panc_emp_id" id="adhc_emp_id" value="" />
                        <input type="hidden" name="panc_emp_id" id="adhc_comp_id" value="" />
                        <div className="card-body">
                            <div className="form-group">
                                <label for="exampleInputEmail1">UAN No.</label>
                                <input type="text" className="form-control" id="col1" name="col1" placeholder="UAN No." />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Upload UANNo.</label>
                            </div>
                            <div className="form-group">
                            <img id="uan_img" name="uan_img"  setTo="profile_pic_upd" className="_brFile uan_img" src={TkMeter} alt={"df"}/>
                            <input className="updImg" type="file" ref_img="uan_img" name="uan_img_upd" id="uan_img_upd" />
                            </div>
                            <div className="respUAN"></div>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-primary" onclick="getImgCert(this.form,'updPanCard','frmUANNo','respUAN')">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>);
    }
}
export default User_UAN_No;