import $ from 'jquery';
import React from 'react';
import LogoImg from '../assets/images/logo_p_small.png';
import UserToken from '../function_lib/userToken';
import {setProfilePic,defaultClick,getAccessRolesNew,CallMapMethod} from '../function_lib/common_lib';
import UserDefault from '../assets/images/9.png';


import './header.css';
let listRoles;
let loginUser;
let userObject;
let companyName="";
class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="./";
  }
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      listRoles= UserToken.getAccessRoles();
      userObject= UserToken.getUserObject();
      //alert(userObject.length);
      loginUser=userObject[0].person_name;
      companyName=userObject[0].company_name;
      //alert(listRoles.length);
    }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
     alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="./";
   }
  }
  componentDidMount(){
    // var map=[];
    //     map.push({name:"curl_type",value:"getUserStatus"});
    //     map.push({name:"res_func",value:"callBackNg"});
    //     map.push({name:"gb_token",value:userObject[0].app_user_id});
    //     map.push({name:"rf",value:userObject[0].id});
    //     map["res_func"]="callBackNg";
    //     map["curl_type"]="getUserStatus";
    //     CallMapMethod(this,map);
    if(listRoles.length!=0){
      var listHtm=getAccessRolesNew(listRoles);
     // setProfilePic(document.getElementById("rightProf_Img"),userObject[0].primary_img);
      //alert(listHtm);
      $("#menu_opt").append(listHtm);
    }
    
  }
    render(){
        return(//  <!-- Navbar -->
          <div>
          <nav className="main-header navbar navbar-expand-md  navbar-light navbar-white my-shadow">
          <div className="container-fluid "> <a href="/dashboard" className="navbar-brand"> <img src={LogoImg} alt="HrTech" className="brand-image elevation-3 img-thumbnail" /> <span className="brand-text " ></span> </a>
            <button className="navbar-toggler order-1 me-hide" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span> </button>
            <div className="collapse navbar-collapse order-3" id="navbarCollapse"> 
           
              {/* <!-- Left navbar links -->  */}
              
              {/* <!-- SEARCH FORM -->  */}
              
            </div>
          
            
            {/* <!-- Right navbar links --> */}
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* <select className="form-control">
                 <option>Year</option>
                 <option>2021</option>
            </select> */}
              {/* <!-- Messages Dropdown Menu --> */}
             
        
            
              <li className="nav-item dropdown12"> <a className="nav-link" data-toggle="dropdown" href="#"> <i style={{fontSize:"2em"}} className="fa fa-user fa-2x"></i> </a>
                <div id="dropdown12" className="dropdown-menu dropdown-menu-lg dropdown-menu-right"> 
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item"> <i className="fa fa-envelope mr-2"></i> Welcome {loginUser}</a>
                  <div className="dropdown-divider"></div>
                  
                  <a href="javascript:void(0)" onClick={()=>this.logout()} className="dropdown-item"> <i className="fa fa-file mr-2"></i> Logout </a>
                  <div className="dropdown-divider"></div>
                  </div>
              </li>
            </ul>
          </div>
        </nav>
          <nav class="navbar navbar-expand-lg my-bg-color-1 my-shadow">
          <a class="navbar-brand text-white ml-3" href="#"><i class="fa fa-user-circle"></i> {loginUser}  </a>
          <ul class="navbar-nav">
              
          </ul>
        
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fa ffg fa-bars "></i></span>
          </button>
        
          <div class="collapse navbar-collapse" id="nav">
        
        
              <ul id="menu_opt" class="navbar-nav bg-light-my m-0 ml-lg-auto p-3 p-lg-0 m-auto">
              <li class="d-inline d-lg-none">
                <button data-toggle="collapse" data-target="#nav" class="close float-right">&times;</button>
            </li>
            <li class="nav-item d-lg-none d-md-none d-sm-none  "><img src={UserDefault} width="80" class="img-circle img-thumbnail img-fluid m-auto text-cente" />
				<h6 class="mt-4">Good-Morning {loginUser} </h6>
				<div class="border-bottom w-100 mt-1 mb-1"></div>
			
			</li>
              </ul>
          </div>
      </nav>


      </div>
          )
    }
}
export default Header