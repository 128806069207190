import React from 'react';
class Add_Emp_Details extends React.Component{
    render(){
        return(<div class="modal fade" id="empdlt">
        <div class="modal-dialog">
            <div class="modal-content">
    
    
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Employment Details</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    <form id="frmEmp" role="form">
                        <input type="hidden" name="emp_emp_id" id="emp_emp_id" value="" />
                        <input type="hidden" name="emp_comp_id" id="emp_comp_id" value="" />
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insEmpmentDetails" />
                        <input type="hidden" name="m_type" id="m_type" value="insEmpmentDetails" />
                        <input type="hidden" name="type_id" id="type_id" value="20" />
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Organization Name</label>
                                <input type="text" class="form-control" id="col1" name="col1" placeholder="Organization Name" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Designation</label>
                                <input type="text" class="form-control" id="col2" name="col2" placeholder="Designation" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Manager Name</label>
                                <input type="text" class="form-control" id="col7" name="col7" placeholder="Manager Name" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Manager Role</label>
                                <input type="text" class="form-control" id="col8" name="col8" placeholder="Manager Role" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Date of Joining</label>
                                <input type="text" class="form-control" id="col3" name="col3" placeholder="Date of Joining" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Date of Leaving</label>
                                <input type="text" class="form-control" id="col4" name="col4" placeholder="Date of Leaving" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Annual CTC</label>
                                <input type="text" class="form-control" id="col5" name="col5" placeholder="Annual CTC" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Monthly in Hand</label>
                                <input type="text" class="form-control" id="col6" name="col6" placeholder="Monthly in Hand" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputFile">File input</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                                        <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                    </div>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="">Upload</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
    
                        <div class="card-footer">
                            <button type="button" class="btn _btnSetEmp btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
    
    
            </div>
           
        </div>
        
    </div>)
    }
}
export default Add_Emp_Details;