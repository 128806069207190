import $ from 'jquery';
import React from 'react';
import defaultPic from '../assets/images/9.png';
import TkMeter from '../assets/images/camera.svg';
import {TableDatePicker} from '../function_lib/common_lib';
function submitTrip(){
    alert("submitMe");
}
const GenerateSalary=(rf=0,props)=>{
    return(
        <div className="modal fade bd-example-modal-xl"  id="divGenSalary" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h6 className="modal-title text-white" id="exampleModalLabel">Generate Salary</h6>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
    
    <div className="container">
    <form action="#" method="post">
                <input type="hidden" id="curl_type" name="curl_type" value="submitLeaveReq" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="m_type" id="m_type" value="submitLeaveReq" />
                <input type="hidden" name="parent_id" id="parent_id" value="" />
                <input type="hidden" name="ref2_id" id="ref2_id" value="" />
                <div className="mb-3">
                  <div className="title mb-2"><i className="lni lni-map-marker"></i><span>Select Month</span></div>
                  <select id="col1" name="col1" className="form-control">
                     
                  </select>
                </div>
               
             
             
                <div id="getReponse" style={{color:"red"}}></div>
               
                <button className="btn btn-success w-100 _btnSetColor" type="button">Submit</button>
              </form>
   
  </div>
</div>
</div>
</div>)
}
export default GenerateSalary;