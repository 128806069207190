import React from 'react';
import TkMeter from '../assets/images/camera.svg';
class User_Aadhar extends React.Component{
    render(){
        return(<div id="ltAadharCard" className="modal fade">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className="card-title">Aadhar Card.</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="POST" className="form-group" id="frmAadharCard" name="frmAadharCard" enctype="multipart/form-data">
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="createAppUser" />
                        <input type="hidden" name="m_type" id="m_type" value="createAppUser" />
                        <input type="hidden" name="panc_emp_id" id="adhc_emp_id" value="" />
                        <input type="hidden" name="panc_emp_id" id="adhc_comp_id" value="" />
                        <div className="card-body">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Aadhar No.</label>
                                <input type="text" className="form-control" id="col1" name="col1" placeholder="Aadhar No." />
                            </div>
                           
                            <div className="form-group">
                            <label for="exampleInputEmail1">Upload Aadhar</label>
                            <img id="updadh_img" name="updadh_img"  setTo="aadhar_pic_upd" className="_brFile updadh_img" src={TkMeter} alt={"df"}/>
                            <input className="updImg" type="file" ref_img="updadh_img" name="aadhar_pic_upd" id="aadhar_pic_upd" />
                               
                            </div>
                            <div className="respAdh"></div>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-primary" onclick="getImgCert(this.form,'updPanCard','frmAadharCard','respAdh')">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
    }
}
export default User_Aadhar;
