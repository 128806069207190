import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import defBlankPic from '../assets/images/default_blank.png';

import Itr_Declaration from '../components/itr_declaration';
import Profile_User from '../components/profile_user';
import Salary_Slip from '../components/salary_slip';
import User_Pancard from '../components/user_pancard';
import User_Aadhar from '../components/user_aadhar';
import User_UAN_No from '../components/user_uan_no';
import User_ESIC_No from '../components/user_esic_no';


let loginUser="";
let userObject="";
let companyName="";
class Profile extends React.Component{
    constructor(props){
        super(props);
        this.setState(state=>({setProfile:"",isProfile:false}));
     
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
         // UserToken.setUserObject(localStorage.getItem("userData"));
         // userObject= UserToken.getUserObject();
         // loginUser=userObject[0].person_name;
          //companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
     getBasicProfile(){

     } 
    componentDidMount(){
      AjaxCallMethod(this,"okSuccCNOT");
    }
    
    

      render(){
          return(<div>
              <Header/>
              <div className="content">
  <div className="container-fluid mt-4 ">
    <div className="row">
    <div className="col-md-2">

    <div className="card card-widget widget-user-2 shadow "> 
   <div className="card-footer p-0">
    <ul className="nav flex-column  table-hover">
      <li className="nav-item"> <a href="#profile_main"  data-toggle="tab" className="nav-link  hover"><h6> <i className="fa fa-user font-small text-orange"></i>&nbsp;&nbsp; Profile Detail</h6> </a> </li>
      <li className="nav-item"> <a href="#it_declaration"  data-toggle="tab" className="nav-link  hover" ><h6> <i className="fa fa-credit-card font-small text-orange"></i>&nbsp;&nbsp; Applicable Salary Group</h6> </a> </li>
      <li className="nav-item"> <a href="#it_declaration"  data-toggle="tab"  className="nav-link  hover"><h6> <i className="fa fa-user-secret font-small text-orange"></i>&nbsp;&nbsp; I.T. Declearation</h6> </a> </li>
      <li className="nav-item"> <a href="#salary_slip"  data-toggle="tab" className="nav-link  hover"><h6> <i className="fa fa-credit-card font-small text-orange"></i>&nbsp;&nbsp; Get Salary Slip</h6> </a> </li>
    </ul>
  </div>
</div>
<div className="card card-widget widget-user-2 shadow "> 
  <div className="card-footer p-0">
    <ul className="nav flex-column">
      <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Today's Attendance<span className="float-right badge bg-primary">2</span> </a></h6> </li>
      <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Trips <span className="float-right badge bg-info">5</span> </a></h6> </li>
      <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Leaves <span className="float-right badge bg-success">12</span> </a></h6> </li>
      <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Requests <span className="float-right badge bg-danger">42</span> </a></h6> </li>
    </ul>
  </div>
  
  
</div>
<div class="card card-widget widget-user-2 shadow "> 
  <div class="card-footer p-0">
    <ul class="nav flex-column">
      <li class="nav-item"> <a href="javascript:void(0)" data-toggle="modal" data-target="#ltPanCard" class="nav-link"><h6><i class="fa fa-angle-double-right text-orange"></i> PAN No:</h6><span class="float-right badge bg-primary" data-toggle="modal" data-target="#exampleModal">Browse</span>
        <div class="w-100 mb-2"></div>
        <img src={defBlankPic} class="img-fluid m-auto mt-3 d-block img-thumbnail" width="100" /> </a> </li>
      <li class="nav-item"> <a href="javascript:void(0)" data-toggle="modal" data-target="#ltAadharCard" class="nav-link"><h6><i class="fa fa-angle-double-right text-orange"></i> Aadhaar No:</h6> <span class="float-right badge bg-primary" data-toggle="modal" data-target="#exampleModal">Browse</span>
        <div class="w-100 mb-2"></div>
        <img src={defBlankPic} class="img-fluid m-auto mt-3 d-block img-thumbnail" width="100" /> </a> </li>
      <li class="nav-item"> <a href="javascript:void(0)" data-toggle="modal" data-target="#ltUANNo" class="nav-link"><h6><i class="fa fa-angle-double-right text-orange"></i> UAN No</h6> <span class="float-right badge bg-primary" data-toggle="modal" data-target="#exampleModal">Browse</span>
        <div class="w-100 mb-2"></div>
        <img src={defBlankPic} class="img-fluid m-auto mt-3 d-block img-thumbnail" width="100" /> </a> </li>
      <li class="nav-item"> <a href="javascript:void(0)" class="nav-link"><h6><i class="fa fa-angle-double-right text-orange"></i> ESIC No:</h6> <span class="float-right badge bg-primary" data-toggle="modal" data-target="#ltESICNo">Browse</span>
        <div class="w-100 mb-2"></div>
        <img src={defBlankPic} class="img-fluid m-auto mt-3 d-block img-thumbnail" width="100" /> </a> </li>
    </ul>
  </div>
</div>
    </div>
    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
    <div class="tab-content">
    <Profile_User/>
<Itr_Declaration/>
<Salary_Slip/>
</div>
    </div>
    </div>
  </div>
  </div>
<User_Pancard/>
<User_Aadhar/>
<User_UAN_No/>
<User_ESIC_No/>

          </div>)
      }
}
export default Profile;