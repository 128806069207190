import React from 'react';
class Add_Bank_Details extends React.Component{
    render(){
        return(<div class="modal fade" id="bankdlt">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Bank Details</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                   
                    <form id="frmBank" role="form">
                        <input type="hidden" name="bank_emp_id" id="bank_emp_id" value="" />
                        <input type="hidden" name="bank_comp_id" id="bank_comp_id" value="" />
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insBankDetails" />
                        <input type="hidden" name="m_type" id="m_type" value="insBankDetails" />
                        <input type="hidden" name="type_id" id="type_id" value="21" />
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Bank Name</label>
                                <input type="text" class="form-control" id="col1" name="col1" placeholder="Bank Name" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">IFSC Code</label>
                                <input type="text" class="form-control" id="col2" name="col2" placeholder="IFSC Code" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Branch Details</label>
                                <input type="text" class="form-control" id="col3" name="col3" placeholder="Branch Details" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">A/c No.</label>
                                <input type="text" class="form-control" id="col4" name="col4" placeholder="Contact No." />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Confirm A/c No.</label>
                                <input type="text" class="form-control" id="col5" name="col5" placeholder="Email ID" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputFile">Upload Bank Cheque/ Statement</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                                        <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                    </div>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="">Upload</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
    
                        <div class="card-footer">
                            <button type="button" class="btn _btnSetBank btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
    
    
            </div>
           
        </div>
        
    </div>);
    }
}
export default Add_Bank_Details;
