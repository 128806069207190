import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod} from '../function_lib/common_lib';
import defaultPic from '../assets/images/9.png';
import Add_Edu_Details from '../components/add_edu_details';
import Add_Emp_Details from '../components/add_emp_details';
import Add_Bank_Details from '../components/add_bank_details';
import Add_Family_Details from '../components/add_family_details';
import Upload_Profile_Pic from '../components/upload_profile_pic';

let loginUser="";
let userObject="";
let companyName="";
class Profile_User extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
  constructor(props){
    super(props);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.state={setFullProfile:"",isFullProfile:false};
    if(localStorage.getItem("userData")!=null){
        userObject= getUserObjData();
        //alert(JSON.stringify(userObject));
     // UserToken.setUserObject(localStorage.getItem("userData"));
     // userObject= UserToken.getUserObject();
     // loginUser=userObject[0].person_name;
      //companyName=userObject[0].company_name;
      //window.location.href="./Dashboard";
    }
  }
  getFullProfile(){
    var mapCat=[];
    mapCat.push({name:"curl_type",value:"getProfileLists"});
    mapCat.push({name:"res_func",value:"cbFullProfile"});
    mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
    mapCat.push({name:"emp_id",value:userObject[0].id});
    mapCat["res_func"]="cbFullProfile";
    mapCat["curl_type"]="getProfileLists";
    CallMapMethod(this,mapCat);
   }
   cbFullProfile(data,msg){
    //alert("Full Profile "+data);
    if(data!==""){
     this.setState(state=>({setFullProfile:JSON.parse(data),isFullProfile:true}));
     this.forceUpdateHandler();
    }
    
  }
  componentDidMount(){
    this.getFullProfile();
   }
    render(){
        return(<div id="profile_main" class="card active tab-pane">
        <div class="card-header bg-cyan"> <i class="fa fa-user font-small"></i> <b>User Profile </b> </div>
        <div class="card-body" >
          <div class="main-body"> 
            
          
            
            <div class="row gutters-sm">
              <div class="col-md-3 mb-1">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-column align-items-center text-center"> <img src={defaultPic} alt="Admin" class="rounded-circle" width="150" />
                      <div class="mt-3">
                        <h4>{userObject[0].person_name}</h4>
                        <p class="text-secondary mb-1">Developer</p>
                        <p class="text-muted font-size-sm">{userObject[0].address}</p>
                        <button data-toggle="modal" data-target="#updProfilePic" class="btn btn-primary"><i class="fa fa-edit"></i>Update Profile Pic</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-3"> </div>
              </div>
              <div class="col-md-9">
                <div class="card mb-2">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Full Name</h6>
                          </div>
                          <div class="col-sm-8 text-secondary">{userObject[0].person_name}</div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 border-left my-ff">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">About</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> Mohan yadav with family </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Email</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> Mohanyadav@gmail.com </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 border-left my-ff">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Phone No.</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> +91 7869887765 </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">class</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> ### </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 border-left my-ff">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Office Address</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> LIG schhjdfk no 3. in front </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Land Line No</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> Mohan yadav </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 border-left my-ff">
                        <div class="row">
                          <div class="col-sm-4">
                            <h6 class="mb-0">Memeber</h6>
                          </div>
                          <div class="col-sm-8 text-secondary"> Mohan yadav </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="col-sm-2">
                        <h6 class="mb-0">Address</h6>
                      </div>
                      <div class="col-sm-9 text-secondary">{userObject[0].address}</div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="col-sm-12"> <a class="btn btn-primary text-white mb-4"  href="#"><i class="fa fa-edit"></i> Edit</a> </div>
                    </div>
                    <small class="mt-4">Data Progressbar</small>
                    <div class="progress mb-3" style={{height:"10px"}}>
                      <div class="progress-bar bg-primary" role="progressbar" style={{width:"80%","aria-valuenow":"80","aria-valuemin":"0","aria-valuemax":"100"}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="card card-tabs">
                  <div class="card-header p-0 pt-1">
                    <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                      <li class="nav-item"> <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#pro_user_edu_det" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true"><i class="fa fa-envelope-open text-orange"></i> Educational Detail</a> </li>
                      <li class="nav-item"> <a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#pro_user_emp_det" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false"><i class="fa fa-flag text-orange"></i> Last 3 Employement Detail</a> </li>
                      <li class="nav-item"> <a class="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#pro_user_bank_det" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false"><i class="fa fa-folder-open text-orange"></i> Bank Detail </a> </li>
                      <li class="nav-item"> <a class="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" href="#pro_user_family_det" role="tab" aria-controls="custom-tabs-one-settings" aria-selected="false"><i class="fa  fa-address-book text-orange"></i> Family Members</a> </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div class="tab-pane fade show active" id="pro_user_edu_det" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                      <div class="card-body p-0">
                                                    <div class="mailbox-controls">
                                                        
                                                        <button type="button" class="btn btn-default btn-sm checkbox-toggle">
                                                            <i class="fa fa-square"></i>
                                                        </button>
                                                        <div class="btn-group">
                                                            <button type="button" data-toggle="modal" data-target="#edudlt" class="btn btn-default btn-sm"><i class="fa fa-plus"></i></button>
                                                        </div>
                                                       
                                                        <button type="button" class="btn btn-default btn-sm"><i class="fa fa-sync-alt"></i></button>
                                                        <div class="float-right">
                                                            Total - 0
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="table-responsive mailbox-messages">
                                                        <table class="table table-hover table-striped">
                                                        <thead>
                                                           <tr class="border">
                                                              <th>S.No.</th>
                                                              <th>Education Name</th>
                                                              <th>College/University</th>
                                                              <th>Passing Year</th>
                                                                <th>Performance%</th>
                                                                <th>Documents</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                  </thead>
                                                            <tbody id="dtEdu">
                                                              {(this.state.isFullProfile)?this.state.setFullProfile.map((item,index)=>{
                                                                if(item.type_id=="19"){
                                                                  return <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.col1}</td>
                                                                    <td>{item.col2}</td>
                                                                    <td>{item.col3}</td>
                                                                    <td>{item.col4}</td>
                                                                    <td>{item.col5}</td>
                                                                    <td>{item.col6}</td>
                                                                  </tr>
                                                              }
                                                            }
                                                             
                                                              ):''}
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    
                                                </div>
                     </div>
                      <div class="tab-pane fade" id="pro_user_emp_det" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab"> 
                      <div class="card-body p-0">
                                                    <div class="mailbox-controls">
                                                        
                                                        <button type="button" class="btn btn-default btn-sm checkbox-toggle">
                                                            <i class="fa fa-square"></i>
                                                        </button>
                                                        <div class="btn-group">
                                                            <button type="button" data-toggle="modal" data-target="#empdlt" class="btn btn-default btn-sm"><i class="fa fa-plus"></i></button>
                                                        </div>
                                                       
                                                        <button type="button" class="btn btn-default btn-sm"><i class="fa fa-sync-alt"></i></button>
                                                        <div class="float-right">
                                                            Total - 0
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="table-responsive mailbox-messages">
                                                        <table class="table table-hover table-striped">
                                                        <thead>
                                                           <tr class="border">
                                                              <th>S.No.</th>
                                                              <th>Organization Name</th>
                                                              <th>Designation</th>
                                                              <th>Manager Name</th>
                                                                <th>Role</th>
                                                                <th>Date of Joining</th>
                                                                <th>Date of Leaving</th>
                                                                <th>Annual CTC</th>
                                                                <th>Monthly in Hand</th>
                                                                <th>Attachements</th>
                                                                <th>Action</th>
                                                            </tr>
                  </thead>
                                                            <tbody id="dtEmp">
                                                            {(this.state.isFullProfile)?this.state.setFullProfile.map((item,index)=>{
                                                                if(item.type_id=="20"){
                                                                  return <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.col1}</td>
                                                                    <td>{item.col2}</td>
                                                                    <td>{item.col3}</td>
                                                                    <td>{item.col4}</td>
                                                                    <td>{item.col5}</td>
                                                                    <td>{item.col6}</td>
                                                                  </tr>
                                                              }
                                                            }
                                                             
                                                              ):''}
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    
                                                </div>
                      </div>
                      <div class="tab-pane fade" id="pro_user_bank_det" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab"> 
                      <div class="card-body p-0">
                                                    <div class="mailbox-controls">
                                                        
                                                        <button type="button" class="btn btn-default btn-sm checkbox-toggle">
                                                            <i class="fa fa-square"></i>
                                                        </button>
                                                        <div class="btn-group">
                                                            <button type="button" data-toggle="modal" data-target="#bankdlt" class="btn btn-default btn-sm"><i class="fa fa-plus"></i></button>
                                                        </div>
                                                       
                                                        <button type="button" class="btn btn-default btn-sm"><i class="fa fa-sync-alt"></i></button>
                                                        <div class="float-right">
                                                            Total - 0
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="table-responsive mailbox-messages">
                                                        <table class="table table-hover table-striped">
                                                        <thead>
                                                           <tr class="border">
                                                              <th>S.No.</th>
                                                              <th>Bank Name</th>
                                                              <th>IFSC Code</th>
                                                              <th>Branch </th>
                                                                <th>A/c No</th>
                                                                <th>Attachments</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                  </thead>
                                                            <tbody id="dtBank">
                                                            {(this.state.isFullProfile)?this.state.setFullProfile.map((item,index)=>{
                                                                if(item.type_id=="21"){
                                                                  return <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.col1}</td>
                                                                    <td>{item.col2}</td>
                                                                    <td>{item.col3}</td>
                                                                    <td>{item.col4}</td>
                                                                    <td>{item.col5}</td>
                                                                    <td>{item.col6}</td>
                                                                  </tr>
                                                              }
                                                            }
                                                             
                                                              ):''}
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    
                                                </div>
                      </div>
                      <div class="tab-pane fade" id="pro_user_family_det" role="tabpanel" aria-labelledby="custom-tabs-one-settings-tab">
                      <div class="card-body p-0">
                                                    <div class="mailbox-controls">
                                                        
                                                        <button type="button" class="btn btn-default btn-sm checkbox-toggle">
                                                            <i class="fa fa-square"></i>
                                                        </button>
                                                        <div class="btn-group">
                                                            <button type="button" data-toggle="modal" data-target="#famdlt" class="btn btn-default btn-sm"><i class="fa fa-plus"></i></button>
                                                        </div>
                                                       
                                                        <button type="button" class="btn btn-default btn-sm"><i class="fa fa-sync-alt"></i></button>
                                                        <div class="float-right">
                                                            Total - 0
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="table-responsive mailbox-messages">
                                                        <table class="table table-hover table-striped">
                                                        <thead>
                                                           <tr class="border">
                                                              <th>S.No.</th>
                                                              <th>Member Name</th>
                                                              <th>DOB</th>
                                                              <th>Relation </th>
                                                                <th>Member's Occupation</th>
                                                                <th>If Nominee</th>
                                                                <th>Attachment</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                  </thead>
                                                            <tbody id="dtfamily">
                                                            {(this.state.isFullProfile)?this.state.setFullProfile.map((item,index)=>{
                                                                if(item.type_id=="22"){
                                                                  return <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.col1}</td>
                                                                    <td>{item.col2}</td>
                                                                    <td>{item.col3}</td>
                                                                    <td>{item.col4}</td>
                                                                    <td>{item.col5}</td>
                                                                    <td>{item.col6}</td>
                                                                  </tr>
                                                              }
                                                            }
                                                             
                                                              ):''}
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    
                                                </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        
<Add_Edu_Details/>
<Add_Emp_Details/>
<Add_Bank_Details/>
<Add_Family_Details/>
<Upload_Profile_Pic/>
        </div>);
    }
}
export default Profile_User;