import React from 'react';
class Add_Edu_Details extends React.Component{
    render(){
        return(<div class="modal fade" id="edudlt">
        <div class="modal-dialog">
            <div class="modal-content">
               
              
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Educational Details</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        <form id="frmEdu" role="form">
                            <input type="hidden" name="edu_emp_id" id="edu_emp_id" value="" />
                            <input type="hidden" name="edu_comp_id" id="edu_comp_id" value="" />
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="insEducationDetails" />
                            <input type="hidden" name="m_type" id="m_type" value="insEducationDetails" />
                            <input  type="hidden" name="type_id" id="type_id" value="19" />
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Education Name</label>
                                    <input type="text" class="form-control" id="col1" name="col1" placeholder="Education Name" />
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Collage/University</label>
                                    <input type="text" class="form-control" id="col2" name="col2" placeholder="Collage University" />
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Passing Year</label>
                                    <input type="text" class="form-control" id="col3" name="col3" placeholder="Passing Year" />
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Perfermance %</label>
                                    <input type="text" class="form-control" id="col4" name="col4" placeholder="Performance %" />
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputFile">File input</label>
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="exampleInputFile" />
                                            <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                        </div>
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="">Upload</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
    
                            <div class="card-footer">
                                <button type="button" class="btn _btnSetEdu btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
               
               
            </div>
          
        </div>
        
    </div>)
    }
}
export default Add_Edu_Details;