import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//import moment from 'moment';
//import 'moment/locale/in';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

//import Datetime from 'react-datetime';
//import "react-datetime/css/react-datetime.css";
import { Accordion,Card,Button } from "react-bootstrap";

import {CanvasJSReact} from '../assets/canvasjs.react';
import CorrImg from '../assets/images/doc-green-sign.jpg';
import WrgImg from '../assets/images/doc-red-sign.jpg';

var XMLParser = require('react-xml-parser');
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const appUrl="http://localhost/mydream/";
//const appUrl="http://localhost:65287/Service.svc/";
const appUrl="https://munichredb.ashtechnologies.in/Service.svc/";

export const setProfilePic=(obj, bdata)=> {
  //alert(bdata);
  if(bdata!= null && bdata!=="0"){
    obj.src =  bdata;
  }
}
export const customAlert=(title,msg,urls,oks="0",yesno="0")=>{
   var buttons_=[];
   if(yesno!=="0"){
     buttons_= [
         {
           label: 'Yes',
           onClick: () => window.location.href=urls
         },
         {
           label: 'No',
           onClick: () => window.location.href=urls
         }
       ];
   }else if(oks!=="0"){
     if(urls!=="0"){
      buttons_=[
        {
          label: 'ok',
          onClick: () => window.location.href=urls
        }];
     }else{
      buttons_=[
        {
          label: 'ok',
        }];
     }
      
   }

   confirmAlert({
      title: title,
      message: msg,
      buttons: buttons_
    });
}

export function readURL(input) {
  //if()
  var attr = $(input).attr('ref_count');
  
  if(typeof attr!=='undefined' && attr !== false){
     $('.'+$(input).attr("ref_count")).text("Uploaded Count -"+input.files.length);
  }else{
     if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
  }
  
}

export const getAttendanceList=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["month_nm"]+"</td><td>P:["+lists[i]["Present"]+"]</td><td>A:["+lists[i]["Absents"]+
      "]</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table class=\"table table-sm table-borderless\"></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetTripReports=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      var strToClose="";
      if(lists[i]["tip_status"]==="Open"){
            strToClose="<tr><td style=\"color:red\">Not Completed: </td><td>"+
            "<a href=\"/OnTripComp?ref="+lists[i]["id"]+"\">Click to Mark as Completed</a></td></tr>"
      }
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["entry_date"]+"</td><td>Status :"+lists[i]["tip_status"]+"</td>"+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr>"+"<td>Started From :</td>"+
          "<td>"+lists[i]["trip_start_from"]+"</td></tr><tr>"+
          "<td>Initial Reading : </td>"+"<td>"+lists[i]["init_km_read"]+"</td></tr><tr>"+
          "<td>Destination To :</td>"+"<td>"+lists[i]["trip_desti_to"]+"</td></tr><tr>"+
          "<td>Trip End Km :</td>"+"<td>"+lists[i]["comp_km_read"]+"</td></tr><tr>"+
          "<td>Status :</td>"+"<td>"+lists[i]["tip_status"]+"</td>"+strToClose+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetLeaveRequest=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["col1"]+"</td><td>"+lists[i]["date"]+"</td><td>"+lists[i]["status"]+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr><td colspan=\"3\">"+ lists[i]["details"]+"</td></tr>"+
          "<tr><td>From</td><td>To</td><td>Status</td></tr>"+
          "<tr>"+"<td>"+lists[i]["c_req_from"]+"</td>"+
          "<td>"+lists[i]["c_req_to"]+"</td>"+
          "<td>"+lists[i]["status"]+"</td>"+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
   
}

export function GetGraphData(setData){
  
  //alert(JSON.stringify(titleg));
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Result Score : "+setData.titleg+"%"
    },
          height:300,
    data: [{
      type: "pie",
              radius:"50%",
      startAngle: 30,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: setData.setData
    }]
  }
  return(
    <CanvasJSChart options = {options} />
  );
}
function disableWeekends(date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

export function GetDateDifferecec(fromDt,toDt,dtFormate="dd/mm/yyyy"){
   var arrFromDt=fromDt.split('/');
   var dtFrom = new Date(+arrFromDt[2], arrFromDt[1] - 1, +arrFromDt[0]);
   
   var arrToDt=toDt.split('/');
   var dtTo=new Date(+arrToDt[2], arrToDt[1] - 1, +arrToDt[0]);
   if(dtTo<dtFrom){
    return "0";
   }
   const diffTime = Math.abs(dtTo - dtFrom);
   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
   return diffDays; 
}

export function DateTimePicker(refName){
  var refNm= refName.refName;

  const [date, setDate] = useState(new Date());
  const handleChange = date => setDate(date);

  const today = new Date();
  let in3Days = new Date();
  in3Days.setDate(in3Days.getDate() + 30);
  return (
    <DatePicker id={refNm} name={refNm}  className="form-control" 
    selected={date}
    // timeFormat={"H"}
    onChange={handleChange}
    minTime={"10"}
    maxTime={"17"}
    minDate={today}
    maxDate={in3Days}
    timeIntervals={60}
    hintText="Weekends Disabled" 
    shouldDisableDate={disableWeekends}
  
    showTimeSelect
    dateFormat="dd-MMM-Y hh"
  />
  );
  

}

export function TableDatePicker(refName) {
   //alert(JSON.stringify(refName.refName));
   var refNm= refName.refName;
   //const [date, setDate] = useState(new Date());
   const [date, setDate] = useState(null);
  
   return (
     <DatePicker className="form-control" dateFormat="dd/MM/yyyy" id={refNm} name={refNm}  format='dd-MM-yyyy' selected={date} onChange={date => setDate(date)} />
   );
  }

export const SetResponseDiv=(divName,respTxt)=>{
   $("#"+divName).html(respTxt);
   return "done";
}

export const defaultClick=(obCls,appliedString)=>{
   $(appliedString).click(function(){
   var appFunction =$(this).attr("attEvt");
      obCls[appFunction]("test");
   });
   //return obCls[clFunction]();
}
export const GetCurrentDtTime=()=>{
  var currentDate=new Date();
  return currentDate.toJSON().slice(0,19);
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export const SetDate=(date)=> {
   //z = $(date).attr('value');
   
   var today = new Date();
   var dd = today.getDate();
   var mm = today.getMonth() + 1; //January is 0!

   var yyyy = today.getFullYear();
   if (dd < 10) { dd = '0' + dd }
   if (mm < 10) { mm = '0' + mm }
   //today = yyyy + '-' + mm + '-' + dd;
   today = dd + '/' + mm + "/" + yyyy;
   //today=mm+"/"+dd+"/"+yyyy;
   //alert("check== " + today);
   $(date).text(today);
 
   //$(date).datepicker({dateFormate:'dd/mm/yy'}).val();
}

export const GetImgCert=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
        var resData=data.documentElement.childNodes[0].nodeValue;
       
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
      
         return obCls[resfun](checkData,checkMsg);
          
   },
      error: function(data) {
      
         return obCls[resfun](0,"done");
       }
     };
  
   $(frmNm).ajaxSubmit( options );
}

export const RespBkMthdMapp=(obCls,cBack,objClsName)=>{
  if(objClsName==="chk_activate"){
    $("."+objClsName).click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId,this);
   });
  }else if(objClsName==="_setSearchKeyUp"){
    $("._setSearchKeyUp").keyup(function(e){
      var refId= $(this).val();
         return obCls[cBack](refId);
    });
  }else if(objClsName==="keyEvent"){
    $("."+objClsName).keyup(function(e){
      if(e.keyCode==13){
        //alert("i m in click");
        var refEvent=$(this).attr("rf");
        $("#"+refEvent).click();
      }
    
    });
}else{
    $("."+objClsName).click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  
 
}
export const displayQuest=(index,divClsName)=>{
  var tabcontent = document.getElementsByClassName(divClsName);
  for (var i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  if(index!=-1){
    tabcontent[index].style.display="block";
  }
  
}
export const getAttemptQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  //alert(getLists.length);
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  //var cName=getLists[i]["id"]+"__Q";
  //var hName= getLists[i]["id"]+"__H";
  //var correctAns=getLists[i]["col6"];
var wrongImg="<img alt=\"wrong\" src=\"/doc-red-sign.jpg\"/>";
var rightImg="<img alt=\"right\" src=\"/doc-green-sign.jpg\"/>";
var lbCustA="custom-control-label";
var lbCustB="custom-control-label";
var lbCustC="custom-control-label";
var lbCustD="custom-control-label";


var corColorA="";
var corColorB="";
var corColorC="";
var corColorD="";
if(getLists[i]["col3"]=="a"){
  corColorA= " correct_ans_color";
}else if(getLists[i]["col3"]=="b"){
  corColorB= " correct_ans_color";
}else if(getLists[i]["col3"]=="c"){
  corColorC= " correct_ans_color";
}else if(getLists[i]["col3"]=="d"){
  corColorD= " correct_ans_color";
}

if(getLists[i]["col2"]==getLists[i]["col3"]){
   if(getLists[i]["col2"]=="a"){
     lbCustA="custome-lable-right";
   }else if(getLists[i]["col2"]=="b"){
     lbCustB="custome-lable-right";
   }else if(getLists[i]["col2"]=="c"){
     lbCustC="custome-lable-right";
   }else if(getLists[i]["col2"]=="d"){
     lbCustD="custome-lable-right";
   }
}else{
  if(getLists[i]["col2"]=="a"){
    lbCustA="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="b"){
    lbCustB="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="c"){
    lbCustC="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="d"){
    lbCustD="custome-lable-wrong";
  }
}
    strQuet+="<div id=\""+divName+"\" style=\"display:block\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["question"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustA+" "+corColorA+"\">"+getLists[i]["opt_a"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustB+" "+corColorB+"\">"+getLists[i]["opt_b"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustC+" "+corColorC+"\" for=\""+"c"+"\">"+getLists[i]["opt_c"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustD+" "+corColorD+"\" for=\""+"d"+"\">"+getLists[i]["opt_d"]+"</label>"+
        "</div>"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}
export const getQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  var cName=getLists[i]["id"]+"__Q";
  var hName= getLists[i]["id"]+"__H";
  var correctAns=getLists[i]["col6"];

    strQuet+="<div id=\""+divName+"\" style=\"display:none\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["col1"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"a"+"\" name=\""+cName+"\" value=\"a\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"a"+"\">"+getLists[i]["col2"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"b"+"\" name=\""+cName+"\" value=\"b\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"b"+"\">"+getLists[i]["col3"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"c"+"\" name=\""+cName+"\" value=\"c\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"c"+"\">"+getLists[i]["col4"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"d"+"\" name=\""+cName+"\" value=\"d\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"d"+"\">"+getLists[i]["col5"]+"</label>"+
        "</div>"+
        "<input type=\"hidden\" name=\""+hName+"\" value=\""+correctAns+"\">"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}

export const getAccessRolesNew=(roles)=>{
 //alert(JSON.stringify(roles));
  var getMenu="";
  const listFltr = d => ((d.display_option === 'menu' || d.display_option === 'top_menu') && ( d.parent_id==='0' || d.parent_id==='1'));
  var topMenusList = roles.filter(listFltr);
  //alert(topMenusList.length);
  for (var i = 0; i < topMenusList.length; i++) {
      const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.display_option !== 'desk'
      var childMenuList = roles.filter(childFltr);
      var getChMenu = "";
      //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
      if (childMenuList.length === 0) {
          getMenu += " <li class=\"nav-item  d-sm-inline-block\">" +
              "<a href = \"" + topMenusList[i]["red_link"] + "\" class=\"nav-link nav-menu-top-item\">" + topMenusList[i]["name"] + "</a>" +
              "</li>";
      } else {
          getMenu += "<li class=\"nav-item dropdown\">" +
              "<a class=\"nav-link nav-menu-top-item dropdown-toggle\" href = \"#\" id=\"navbarDropdown_m"+i+"\" data-target=\"#navbarDropdown"+i+"\" role=\"button\" data-toggle=\"dropdown\" aria haspopup=\"true\" aria-expanded=\"true\">" + topMenusList[i]["name"] + "</a>"
          getChMenu = "<div id=\"navbarDropdown"+i+"\" class=\"dropdown-menu\" aria-labelledby=\"navbarDropdown\">";
          for (var ch = 0; ch < childMenuList.length; ch++) {
              getChMenu += "<a class=\"dropdown-item\" href=\"" + childMenuList[ch]["red_link"] + "\">" + childMenuList[ch]["name"] + "</a>";
          }
          getChMenu += "</div>";
          getMenu += getChMenu + "</li>";
      }

      // getMenu += topMenusList[i]["col1"];
  }
  return getMenu;
 
}
/*
export const callIframMeth=(iFrame)=>{
  alert("Initializing events Final Testing");
  var iframe = document.getElementById(iFrame);
  var myIframe = document.getElementById(iFrame).contentWindow;
  myIframe.addEventListener("keydown", function(evt) {
    myIframe.postMessage(
        JSON.stringify({
            type:"KeyboardEvent",
            evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
        })
    ); 
});
myIframe.addEventListener("keyup", function(evt) {
  myIframe.postMessage(
      JSON.stringify({
          type:"KeyboardEvent",
          evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
      })
  ); 
});
}
*/
export const callIframMeth=(iFrame)=>{
  var myIframe = document.getElementById(iFrame);
  window.addEventListener("keydown", evt => {
    if (myIframe.contentWindow) myIframe.contentWindow.postMessage(
      JSON.stringify({
        type:"KeyboardEvent",
        evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
      })
      ,"*"
    ); 
  });

  window.addEventListener("keyup", evt => {
    if (myIframe.contentWindow) myIframe.contentWindow.postMessage(
      JSON.stringify({
          type:"KeyboardEvent",
          evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
      })
      , "*"
    ); 
  });

}
/*
export const callIframMeth=(iFrame)=>{
  alert("Initiated keydown Events Testing 20..");
 var myIframe = document.getElementById("simulIF").contentWindow;
window.addEventListener("keydown", function(evt) {
    myIframe.postMessage(
        JSON.stringify({
            type:"KeyboardEvent",
            evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
        })
    ); 
});

window.addEventListener("keyup", function(evt) {
    myIframe.postMessage(
        JSON.stringify({
            type:"KeyboardEvent",
            evt: {key:evt.key, code: evt.code, type: evt.type, which: evt.which}
        })
    ); 
});
}
*/



export const CallMapMethod=(obCls,frmValues)=>{
   
  var curl_type=frmValues['curl_type'];

    //alert("check url == "+curl_type);
    var divRes="";
    var getCallFunc=""
    if(frmValues['res_name'])
    {
      divRes=frmValues['res_name'];
    }

    if(frmValues['res_func'])
    {
      getCallFunc=frmValues['res_func'];
    }
        var ss=frmValues;
        var xs = "{'objs':" + JSON.stringify(ss) + "}";
       //alert(xs);
         $.ajax({
            crossDomain:true,
                type: "POST",
                url: appUrl+curl_type,
                data:xs,
             success: function (data) {
                //alert(data.d);
                 //ajaxindicatorstop();
                 // alert(data.d);
                 // return data;0
               
                 if(divRes!="")
                 {
                     if ($("#" + divRes).length!=0)
                  $("#"+divRes).html(data);
                 }
                 if(getCallFunc!="")
                 {
                  var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                  var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                  //  eval(getCallFunc);
                 return obCls[getCallFunc](checkData,checkMsg);
                    }
                   
                }
            });
}

export const GetImgCertUser=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
        //alert(data);
        //var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
      //alert(JSON.stringify(data));
         return obCls[resfun](0,data);
       }
     };
   /* for validation control */
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="1px solid #e87d1e";
       isValidated=false;
     }
   }
   if(!isValidated){
     return obCls[resfun]("not_validated","","");
   }else{
    $(frmNm).ajaxSubmit( options );
   }
}

export const AjaxCallMethod = (obCls,cBack)=>{
  //Normal Click
   $('._brFile').click(function(){
      var getNm=$(this).attr("setTo");
      
      document.getElementById(getNm).click();
      });
   $(".updImg").change(function(){
      readURL(this);
      //alert("A file has been selected.");
  });
  $(".select_target").change(function () {
    var getCallFunc= $(this).attr("resp_dest");
    var refId=$(this).val();
    return obCls[getCallFunc](refId);
   });
    $('._btnSetColor').click(function () {
    //var m_type=$("#m_type").val();
   //var curl_type=$("#curl_type").val();
 //$("#modal_login").serialize();
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();
   
   var xs = "{objs:" + JSON.stringify(ss) + "}";
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
   //alert(xs);
  
   //ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
          crossDomain: true,
               type: "POST",
            url: appUrl + curl_type,
            data: xs,
             success: function (data) {
               //alert(data);
                //ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
                
               }
           });
});
}
export default AjaxCallMethod