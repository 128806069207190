import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import defBlankPic from '../assets/images/default_blank.png';

import New_Claim_Comp from '../components/new_claim_comp';


let loginUser="";
let userObject="";
let companyName="";
class New_Claim extends React.Component{
    constructor(props){
        super(props);
        this.setState(state=>({setProfile:"",isProfile:false}));
     
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
         // UserToken.setUserObject(localStorage.getItem("userData"));
         // userObject= UserToken.getUserObject();
         // loginUser=userObject[0].person_name;
          //companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
      importClaim(){
        GetImgCertUser(this,"#frmClaimImport","importExcelClaim","callBkImportClaim");
      }
      callBkImportClaim(data,datares,userResp){
          //alert(data);
          $("#resImportClaim").html(data);
      }
     getBasicProfile(){

     } 
    componentDidMount(){
      AjaxCallMethod(this,"okSuccCNOT");
    }
    
    

      render(){
          return(<div>
              <Header/>
              <div className="content">
  <div className="container-fluid mt-4 ">
    <div className="row">
    <div className='col-lg-2 col-md-2 col-sm-4 col-2' style={{marginBottom:"10px"}}><button type="button" data-toggle="modal" data-target="#divClaimImport" class="btn btn-block btn-primary btn-sm">Import CSV</button></div>
    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="tab-content">
   
<New_Claim_Comp />

</div>
    </div>
    </div>
  </div>
  </div>
  <div className="modal fade bd-example-modal-xl"  id="divClaimImport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Bulk Import CSV File</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmClaimImport" name="frmClaimImport" enctype="multipart/form-data" method="POST" action="#">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                      
                        
                           
                            <input type="hidden" name="app_user_id" id="app_user_id" value={userObject[0].id} />
                            <div className="card-body">
                                <div className="row">
                                   
                                    <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
<label for="exampleInputFile">File input</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  <div className="input-group-append">
  <button type="button" onClick={()=>this.importClaim()} className="btn btn-primary">Upload Now</button>
  </div>
</div>
</div>
                                    </div>
                                 

                                   
                                
                                  
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="resImportClaim"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default New_Claim;