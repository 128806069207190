import $ from 'jquery';
import React from 'react';
class Ex_Work_Sheet extends React.Component{
     download(filename) {
        var element = document.createElement('a');
        var setData=document.getElementById("frmExWorkSheet").innerHTML;
        //element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(setData));
       
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
      }
    closeMe(clName){
        // $(clName).modal("hide");
        $(clName).hide();
      }
    constructor(props){
        super(props);
    }  
    componentWillReceiveProps(props){
        this.props=props;
     // alert(JSON.stringify(props.workSheet));
    //   if(this.props.workSheet!==""){
    //     var setData=document.getElementById("frmClaimWorkSheet").innerHTML;
    //     this.download("testing.txt",setData);
    //   }
     
    }
    render(){
        return(<div class="modal fade" id="exWorkSheet" style={{overflow:"scroll"}}>
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Work Sheet</h3>
                        <button type="button" onClick={()=>this.closeMe("#claimWorkSheet")} class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                   <div style={{textAlign:"center"}}>
                       <button onClick={()=>this.download("test.html")} className="btn btn-danger">Download</button>
                   </div>
                    <form id="frmExWorkSheet" role="form">
                       
                        <div className="card-body" style={{overflow:"scroll"}}>
                       
                            {this.props.workSheet!==""?this.props.workSheet.map((item,index)=>{
  return  <table style={{fontSize:"18px"}}><tr>
  <td>Luna no:{item["col1"]} </td>
</tr>
<tr>
  <td>{item["col3"]}:-{item["col6"]} </td>
</tr>
<tr>
  <td>
     Age:{item["col10"]}<br></br>
     Gender:{item["col11"]}<br></br>
     Plan:{item["col18"]}<br></br>
     PT:{item["col19"]};PPT:{item["col20"]}<br></br>
     Sum assured:{item["col36"]}<br></br>
     Education:{item["col12"]}<br></br>
     Occupation :{item["col13"]}<br></br>
     Marital status:{item["col14"]}<br></br>
     Annual income:{item["col15"]}<br></br>
     Resident of :{item["col16"]}<br></br>
     Pincode :{item["col97"]}<br></br>
     Nominee:{item["col67"]}<br></br>
     Channel:{item["col21"]}<br></br> 
  </td>
</tr>
<tr>
  <td>
  ===========================
    <br></br>
     <b>Policy Details:-</b><br></br>
    Policy no. : {item["col17"]}<br></br>
Plan: {item["col18"]+" "+item["col19"]}<br></br>
Proposal sign date : {item["col24"]}<br></br>
RCD : {item["col30"]}<br></br>
Sum Assured: INR {item["col36"]}<br></br>
Policy Duration: {item["col30"]}<br></br>
Premium Mode: {item["col23"]}<br></br>
Premium Amount: INR {item["col22"]}<br></br>
Nominee: {item["col67"]}<br></br>
Nominee Relationship: {item["col68"]}<br></br>
Med/NM: {item["col27"]}<br></br>
UW notes details: {item["col28"]}<br></br>

      
  </td>
</tr>
<tr>
  <td>
  =========================<br></br>
     <b>Claim Details:-</b> <br></br>
    
      Date of Death :{item["col31"]}<br></br>
Cause of Death : {item["col32"]}<br></br>
Place of Death : {item["col16"]}<br></br>
Claimant : {item["col70"]}<br></br>
Claimant KYC : {item["col71"]}<br></br>
Primary Cause of Death:{item["col99"]}<br></br>
Secondary Cause of Death:{item["col100"]}<br></br>
Place of Event :{item["col33"] +(item["col101"]!==""?","+item["col101"]:"")}<br></br>
Negative Indicator:{item["col102"]}<br></br>
Reason for Repudation:{item["col103"]}<br></br>

Date of claim intimation : {item["col35"]}<br></br>
  </td>
</tr>
<tr>
    <td>
        <br></br>
        =========================<br></br>
        <b>Claim Summary:-</b><br></br>
        
  {item["col69"]}
    </td>
</tr>
<tr>
    <td>
        <br></br>
        =================<br></br>
    <b>Investigation finding:-</b><br></br>

{item["col48"]}
    </td>
</tr>
<tr>
    <td>
        <br></br>
        ============<br></br>
    <b>Industry check:</b><br></br>
{item["col72"]}
    </td>
</tr>
<tr>
    <td>
        <br></br>
        ============<br></br>
    <b>Internet check:</b><br></br>

{item["col73"]}
    </td>
</tr>
<tr>
    <td>
        <br></br>
        =================== <br></br>
    <b>Ri account confirmation:</b><br></br>

{item["col65"]}
    </td>
</tr>

<tr>
    <td>
        <br></br>
        =======================<br></br>
    <b>Re-underwriting opinion(if any):</b><br></br>
{item["col49"]}
    </td>
</tr>
<tr>
    <td>
        <br></br>
        =================<br></br>
    <b>MR Recommendation -</b><br></br>

 {item["col50"]}
    </td>
</tr>
</table>

                            }):''}
                          
                        
                          
                         
                        </div>
                        
    
                        {/* <div class="card-footer">
                            <button type="button" class="btn _btnSetBank btn-primary">Submit</button>
                        </div> */}
                    </form>
                </div>
    
    
            </div>
           
        </div>
        
    </div>)
    }
}
export default Ex_Work_Sheet;