import React from 'react';
import New_Claim_Comp from '../components/new_claim_comp';
class Personel_Claim extends React.Component{
    constructor(props){
        super(props);
    }
    componentWillReceiveProps(props){
        this.props=props;
      //alert(JSON.stringify(props.logList));

    }
    render(){
        return(<div class="modal fade" id="viewAttendLog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Edit/View Claim</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                   <New_Claim_Comp itemRow={this.props.logList} />
                    
                </div>
    
    
            </div>
           
        </div>
        
    </div>)
    }
}
export default Personel_Claim;