import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import UserToken from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Upcoming_Events from '../components/upcoming_events';
import Emp_On_Leave from '../components/emp_on_leave';
import Broadcast from '../components/broadcast';
import Mobile_Widgets from '../components/mobile_widgets';
import SubmitTimeIn_Out from '../components/submit_timein_out';
//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
class DashboardNew extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={country:[],line_of_business:[],type_of_clain:[],company:[],group_name:[] };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }

      getAnalyticsData(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getGraphAnalytics"});
        mapCat.push({name:"res_func",value:"callBackDashAnaly"});
        mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        mapCat.push({name:"emp_id",value:userObject[0].id});
        if(userObject[0].nm){
           userType=userObject[0].nm;
        }
        mapCat.push({name:"category_id",value:userType});
        mapCat["res_func"]="callBackDashAnaly";
        mapCat["curl_type"]="getGraphAnalytics";
        CallMapMethod(this,mapCat);
      }
      callBackDashAnaly(data,msg){
          //alert(data+msg);
          if(data!=="" && data!=="0"){
              var getAnalytic=JSON.parse(data);
             
              var arrCountry=new Array();
              arrCountry.push(["Country","Sum Assured"]);
              for(var i=0;i<getAnalytic.country_wise.length;i++){
                  arrCountry.push([getAnalytic.country_wise[i].col2,parseInt(getAnalytic.country_wise[i].sum_assured)]);
              }
              var arrLineBusiness=new Array();
              arrLineBusiness.push(["Line of Business","Sum Assured"]);
              for(var i=0;i<getAnalytic.line_of_business.length;i++){
                if(getAnalytic.line_of_business[i].col4===""){
                    getAnalytic.line_of_business[i].col4="Oth";
                  }
                  arrLineBusiness.push([getAnalytic.line_of_business[i].col4,parseInt(getAnalytic.line_of_business[i].sum_assured)]);
              }
              var arrTypeOfClaim=new Array();
              arrTypeOfClaim.push(["Type of Claim","Sum Assured"]);
              for(var i=0;i<getAnalytic.type_of_claim.length;i++){
                  if(getAnalytic.type_of_claim[i].col6===""){
                    getAnalytic.type_of_claim[i].col6="Oth";
                  }
                 // alert(getAnalytic.type_of_claim[i].col6);
                arrTypeOfClaim.push([getAnalytic.type_of_claim[i].col6,parseInt(getAnalytic.type_of_claim[i].sum_assured)]);
              }
              var arrCompanyClaim=new Array();
              arrCompanyClaim.push(["Company","Approved","Rejected"]);
              for(var i=0;i<getAnalytic.company.length;i++){
                  arrCompanyClaim.push([getAnalytic.company[i]["title"],parseInt(getAnalytic.company[i]["approved_sum"]),parseInt(getAnalytic.company[i]["rejected_sum"])]);
              }
              var arrGroupName=new Array();
              arrGroupName.push(["Group Name","Approved","Rejected"]);
              for(var i=0;i<getAnalytic.group_name.length;i++){
                  arrGroupName.push([getAnalytic.group_name[i]["title"],parseInt(getAnalytic.group_name[i]["approved_sum"]),parseInt(getAnalytic.group_name[i]["rejected_sum"])]);
              }
              //alert(arrGroupName.length);
              //alert(JSON.stringify(arrGroupName));
              this.setState({group_name:arrGroupName});
              this.setState({company:arrCompanyClaim});
              this.setState({type_of_claim:arrTypeOfClaim});
              this.setState({line_of_business:arrLineBusiness});
              this.setState({country:arrCountry});
              this.forceUpdateHandler();
          }
      }

      componentDidMount(){
        $("#divEmployee").show();
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getGraphDataClaim"});
        mapCat.push({name:"res_func",value:"callBackDash"});
        mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        mapCat.push({name:"emp_id",value:userObject[0].id});
        if(userObject[0].nm){
            userType=userObject[0].nm;
         }
         mapCat.push({name:"category_id",value:userType});
        mapCat["res_func"]="callBackDash";
        mapCat["curl_type"]="getGraphDataClaim";
        CallMapMethod(this,mapCat);
        this.getAnalyticsData();
         // alert(userObject[0].type);
          if(userObject[0].type=="1"){
              alert("Next to Work with..");
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getGraphDataClaim"});
            mapCat.push({name:"res_func",value:"callBackDash"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackDash";
            mapCat["curl_type"]="getGraphDataClaim";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="2"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getGraphDataClaim"});
            mapCat.push({name:"res_func",value:"callBackDash"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackDash";
            mapCat["curl_type"]="getGraphDataClaim";
            CallMapMethod(this,mapCat);
          }
      }
     
      callBackDash(data,msg){
          //alert(data+msg);
          var getDash=JSON.parse(data);
          var objSumAssured=getDash.sum_assured;
             
          $(".dash_tot_amt").html("₹ "+objSumAssured[0].sum_assured+"/-");
          $(".dash_no_claim").html(objSumAssured[0].cc);
          $(".dash_approved_claim").html(objSumAssured[0].approved);
          $(".dash_rejected_claim").html(objSumAssured[0].rejected);
          $(".dash_pend_doc_claim").html(objSumAssured[0].doc_pending);
          $(".dash_pend_claim").html(objSumAssured[0].pending);

          var objTotEmp=getDash.emp_count;
          $(".dash_team_memb").html(objTotEmp[0].cc);

          /*
          $(".tot_comp_quiz").html(getDash.completed_quiz);
          $(".tot_pending_quiz").html(getDash.pending_quiz);
          $(".sim_vs_total").html(getDash.completed_simulator+"/"+getDash.total_simulator);
          
          $(".avg_perform").html(getDash.avg_performance+"%");
          $(".quiz_vs_total").html(getDash.completed_quiz+"/"+getDash.assigned_quiz);
          //$(".not_attempt").html(getDash.pending_quiz);
          //alert(getDash.total_employee);
          */
      }
      callBackSimList(data,msg){
        //  alert(data);
        if(data!=="0" && data!==""){
            var strList="";
            var quizLink="";
            var quizText="";
            var assignList=JSON.parse(data);
            for(var i=0;i<assignList.length;i++){
                if(assignList[i]["status"]=="Pending"){
                   
                    quizText="Start Simulator";
                    quizLink="/preDocuments?rf="+assignList[i]["rf_id"]+
                             "&vId="+assignList[i]["video_url"];
                }else{
                    
                    quizText="View Preformance";
                   quizLink="/certificate?rf="+assignList[i]["rf_id"]
                }
                strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["id"]+"</td>"+
             "<td>"+assignList[i]["n_entry_date"]+"</td>"+
             "<td>"+assignList[i]["col4"]+"</td>"+
             "<td>"+assignList[i]["status"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tdDataSim").html(strList);
        }
      }
      callBackQuizList(data,msg){
          //alert(data);
          var totalQuiz=0;
          var totalPending=0;
          var totalCompleted=0;
        if(data!=="0" && data!==""){
            var assignList=JSON.parse(data);
            var strList="";
            var quizLink="";
            var quizText="";
            //alert(data);
            for(var i=0;i<assignList.length;i++){
                totalQuiz++;
             quizLink="";
             if(assignList[i]["status"]=="Pending"){
                 totalPending++;
                 quizText="Start Quiz";
                 quizLink="/startQuiz?rf="+assignList[i]["rf_id"]+
                          "&vId="+assignList[i]["video_url"];
             }else{
                 totalCompleted++;
                 quizText="View Preformance";
                quizLink="/quizReport?rf="+assignList[i]["rf_id"]
             }
             strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["id"]+"</td>"+
             "<td>"+assignList[i]["n_entry_date"]+"</td>"+
             "<td>"+assignList[i]["col4"]+"</td>"+
             "<td>"+assignList[i]["category_name"]+"</td>"+
             "<td>"+assignList[i]["status"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tdDataDash").html(strList);
            $(".t_totalQuiz").html(totalQuiz);
            $(".t_pendingQuiz").html(totalPending);
            $(".t_completeQuiz").html(totalCompleted);
        }
      }

    render(){
        
        const adminOption=()=>{
            if(userObject[0].type=="3"){
                return(<div className="row">
                    <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Total Animation Video Assigend<span className="float-right badge bg-primary t_totalQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success t_completeQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-info t_pendingQuiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Pending Animation Video</h3>
                
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-ms">
                                                    <thead>
                                                        <tr style={{backgroundColor:"#FC766AFF"}}>
                                                            <th>S.No.</th>
                                                            <th>Request ID</th>
                                                            <th>Assign Date</th>
                                                            <th>Title</th>
                                                            <th>Category</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="tdDataDash">
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="/bedBookings?ref_st=all" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Assigned Simulator</h3>
                
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-ms">
                                                    <thead>
                                                        <tr style={{backgroundColor:"#FC766AFF"}}>
                                                            <th>S.No.</th>
                                                            <th>Request ID</th>
                                                            <th>Assign Date</th>
                                                            <th>Title</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="tdDataSim">
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="/bedBookings?ref_st=all" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                 </div>
                                
                                
                                </div>)
            }
            else if(userObject[0].type=="1"){
                return(<div className="row">
                <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                             {userObject[0].user_type && userObject[0].user_type==="1"?<li className="nav-item">
                                   <a href="/useraccounts" className="nav-link">
                                       Total Employee<span className="float-right badge bg-primary tot_emp"></span>
                                   </a>
                               </li>:''}
                               
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=0" className="nav-link">
                                       Assigned Animation Video <span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=1" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=2" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
               
               <div className="col-md-9">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}></sup></h3>

                                           <p>Average Performance</p>
                                       </div>
                                       <div className="icon">
                                       <i className="fa fa-line-chart"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>

                                           <p>Attempted/Total Animation Video</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-plus"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="sim_vs_total"></div></h3>
                                           <p>Attempted/Total Simulator</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="/simulatorReports" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>);
            }else if(userObject[0].type=="2"){
                return(<div className="row">
                <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                           <li className="nav-item">
                                   <a href="/createcompany" className="nav-link">
                                       Total Companies<span className="float-right badge bg-primary tot_comp"></span>
                                   </a>
                               </li>
                               {userObject[0].user_type && userObject[0].user_type==="1"? <li className="nav-item">
                                   <a href="/useraccounts" className="nav-link">
                                       Total Employee<span className="float-right badge bg-primary tot_emp"></span>
                                   </a>
                               </li>:''}
                              
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=0" className="nav-link">
                                       Assigned Animation Video <span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=1" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=2" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_simu"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_simu"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_simu"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
               
               <div className="col-md-9">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}></sup></h3>

                                           <p>Average Performance</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-line-chart"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>

                                           <p>Attempted/Total Animation Video</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-plus"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="sim_vs_total"></div></h3>

                                           <p>Attempted/Total Simulator</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="/simulatorReports" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>)
            }
            
        }
        return(<div className="wrapper">
            <Header/>
            {/* <Mobile_Widgets/> */}
            <div class="content">
            <div class="container-fluid mt-4">
<div class="row">
<div class="col-md-3">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Total Claimed Amount <span class="float-right badge bg-info dash_tot_amt">0</span></h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
                          
  {userObject[0].user_type && userObject[0].user_type==="1"?<li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-address-book text-orange"></i> Team Members <span class="float-right badge bg-info dash_team_memb"></span></h6>
                              </a>
                          </li>:''}
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left text-orange"></i>Total Claim Requested<span class="float-right badge bg-primary dash_no_claim"></span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i> Claim Approved <span class="float-right badge bg-success dash_approved_claim"></span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i> Claim Rejected <span class="float-right badge bg-danger dash_rejected_claim"></span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i> Claim Pending Documents <span class="float-right badge bg-danger dash_pend_doc_claim"></span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Claim Pending<span class="float-right badge bg-danger dash_pend_claim"></span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>

    {/*New Logics0901 */}
    <div className="col-md-3">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Claims by Country</b>
      
      
      
      
      </div>
      <div className="card-body1" style={{height:"300px",overflow:"scroll"}}>
          {this.state.country.length>0?<Chart
  width={'100%'}
  height={'50vh'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={this.state.country}
  options={{
    title: 'Country Wise',
    // Just add this option
    is3D: true,
  }}
  rootProps={{ 'data-testid': '2' }}
/>
          :''}
      
          </div>
          </div>
          </div>
          <div className="col-md-3">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Claims by Line of Business</b>
      
      
      
      
      </div>
      <div className="card-body1" style={{height:"300px",overflow:"scroll"}}>
      <Chart
  width={'100%'}
  height={'50vh'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={this.state.line_of_business}
  options={{
    title: 'Line of Business',
    // Just add this option
    is3D: true,
  }}
  rootProps={{ 'data-testid': '2' }}
/>
          </div>
          </div>
          </div>
          <div className="col-md-3">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Claims by Type of Claim</b>
      
      
      
      
      </div>
      <div className="card-body1" style={{height:"300px",overflow:"scroll"}}>
      <Chart
  width={'100%'}
  height={'50vh'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={
    this.state.type_of_claim
  }
  options={{
    legend: 'none',
    pieSliceText: 'label',
    title: 'Type of Claim',
    pieStartAngle: 100,
  }}
  rootProps={{ 'data-testid': '4' }}
/>
          </div>
          </div>
          </div>

          <div className="col-md-6">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Claim of Company Approved vs Denied</b>
      </div>
      <div className="card-body" style={{height:"400px",overflow:"scroll"}}>
      <Chart
    width={'100%'}
    height={300}
    chartType="ColumnChart"
    loader={<div>Loading Chart</div>}
    data={this.state.company}
    options={{
      title: 'Approved vs Rejected',
      chartArea: { width: '100%' },
      hAxis: {
        title: 'Company',
        minValue: 0,
      },
      vAxis: {
        title: 'Amount',
      },
    }}
    legendToggle
  />
          </div>
          </div>
          </div>
          <div className="col-md-6">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Group Name Approved vs Denied</b>
      </div>
      <div className="card-body" style={{height:"400px",overflow:"scroll"}}>
      <Chart
    width={'100%'}
    height={300}
    chartType="ColumnChart"
    loader={<div>Loading Chart</div>}
    data={this.state.group_name}
    options={{
      title: 'Approved vs Rejected',
      chartArea: { width: '100%' },
      hAxis: {
        title: 'Group Name',
        minValue: 0,
      },
      vAxis: {
        title: 'Amount',
      },
    }}
    legendToggle
  />
          </div>
          </div>
          </div>
          <div className="col-md-6">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Monthly Claimed Approved vs Denied</b>
      </div>
      <div className="card-body" style={{height:"400px",overflow:"scroll"}}>
      <Chart
  width={'100%'}
  height={'300px'}
  chartType="LineChart"
  loader={<div>Loading Chart</div>}
  data={[
    ['x', 'Approved', 'Denied'],
    [0, 0, 0],
    [1, 10, 5],
    [2, 23, 15],
    [3, 17, 9],
    [4, 18, 10],
    [5, 9, 5],
    [6, 11, 3],
    [7, 27, 19],
  ]}
  options={{
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'Amount',
    },
    series: {
      1: { curveType: 'function' },
    },
  }}
  rootProps={{ 'data-testid': '2' }}
/>
          </div>
          </div>
          </div>
   
 
{/* <!-- /.container-fluid --> */}
</div>
{/* <!-- /.content --> */}
</div>
      </div> 
        <Footer/>
        <SubmitTimeIn_Out/>
      </div>)
    }
}
export default DashboardNew