import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {monthNames,CallMapMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import GenerateSalary from '../components/generate_salary';

let loginUser="";
let userObject="";
let companyName="";
class Create_Salary extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
      }
      }
      callBackNg(data,msg){
        // alert(data);
       if(data.trim()!=="" && data.trim()!=="0"){
         var listItems=JSON.parse(data);
         var str="";
         for(var i=0;i<listItems.length;i++){
          var status = "Not Generated";
          if(listItems[i]["balance"]!==""){
            status = "Salary Slip";
          }
           str+="<tr>"+
            "<td>"+listItems[i]["account_no"]+"</td>"+
            "<td>"+listItems[i]["person_name"]+"</td>"+
            "<td>"+"nn_desig"+"</td>"+
            "<td>"+monthNames[8]+"</td>"+
            "<td>"+listItems[i]["balance"]+"</td>"+
            "<td>"+status+"</td>"+
            "</tr>"
         }
         $("#tbData").html(str);
       }
   }
componentDidMount(){
    var map=[];
    map.push({name:"curl_type",value:"getSalaryList"});
    map.push({name:"res_func",value:"callBackNg"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"ref2_id",value:userObject[0].id});
    map.push({name:"month_nm",value:"09"});
    map["res_func"]="callBackNg";
    map["curl_type"]="getSalaryList";
    CallMapMethod(this,map);
}

      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divGenSalary">Generate Salary</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column">
            <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Month<span className="float-right badge bg-info">sept</span> </a></h6> </li>
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>No of Employee<span className="float-right badge bg-primary">2</span> </a></h6> </li>
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Generated Salary<span className="float-right badge bg-info">5</span> </a></h6> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Employee List</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>Emp Id</th>
                      <th>Name</th>
                      <th>Emp Designation</th>
                      <th>Month</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<GenerateSalary />
          </div>)
      }
}
export default Create_Salary;