import React from 'react';
import TkMeter from '../assets/images/camera.svg';
class User_ESIC_No extends React.Component{
    render(){
        return(<div id="ltESICNo" className="modal fade">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className="card-title">ESIC No.</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="POST" className="form-group" id="frmESICNo" name="frmESICNo" enctype="multipart/form-data">
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="createAppUser" />
                        <input type="hidden" name="m_type" id="m_type" value="createAppUser" />
                        <input type="hidden" name="panc_emp_id" id="adhc_emp_id" value="" />
                        <input type="hidden" name="panc_emp_id" id="adhc_comp_id" value="" />
                        <div className="card-body">
                            <div className="form-group">
                                <label for="exampleInputEmail1">ESIC No.</label>
                                <input type="text" className="form-control" id="col1" name="col1" placeholder="ESIC No." />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Upload ESIC No11.</label>
                            </div>
                            <div className="form-group">
                            <img id="esic_img" name="esic_img"  setTo="esic_upd" className="_brFile esic_img" src={TkMeter} alt={"df"}/>
 <input className="updImg" type="file" ref_img="esic_img" name="esic_upd" id="esic_upd" />
                            </div>
                            <div className="respUAN"></div>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-primary" onclick="getImgCert(this.form,'updPanCard','frmESICNo','respUAN')">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
    }
}
export default User_ESIC_No;