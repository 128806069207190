import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';

import DashboardNew from './user_pages/dashboard_new';
import ListEmployee from './user_pages/list_employes';
import ListTrips from './user_pages/trip_list';
import ListLeave from './user_pages/leave_list';
import ListAttend from './user_pages/attendance_list';
import Create_Salary from './user_pages/create_salary';
import Attendace_List from './user_pages/attendance_list';
import Leave_Request_Mgmt from './user_pages/leave_request_mgmt';
import Trip_Request_Mgmt from './user_pages/trip_request_mgmt';
import Profile from './user_pages/profile';
import New_Claim from './user_pages/new_claim';
import Claim_List from './user_pages/claim_list';

import Masters from './user_pages/masters';
import Check_List_Master from './user_pages/check_list_master';
import Company_Master from './user_pages/company_master';
import Group_Name_Master from './user_pages/group_name_master';
import Cause_of_Event from './user_pages/cause_of_event';
import Cause_Of_Death_Master from './user_pages/cause_of_death_master';

//Remove after done
import TestWhat from './function_lib/testwhats';

class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
      <Route exact path="/Masters" component={Masters}/>

      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/ListEmployee" component={ListEmployee}/>
      <Route exact path="/ListTrips" component={ListTrips} />
      <Route exact path="/ListLeave" component={ListLeave} />
      <Route exact path="/ListAttend" component={ListAttend} />
      <Route exact path="/CreateSalary" component={Create_Salary} />
      <Route exact path="/viewAttendance" component={Attendace_List}/>
      <Route exact path="/LeaveRequest" component={Leave_Request_Mgmt} />
      <Route exact path="/TripRequest" component={Trip_Request_Mgmt}/>
      <Route exact path="/newclaim" component={New_Claim}/>
      <Route exact path="/TestWhat" component={TestWhat} />
      <Route exact path="/claimList" component={Claim_List}/>

      <Route exact path="/companyMaster" component={Company_Master}/>
      <Route exact path="/groupNameMaster" component={Group_Name_Master}/>
      <Route exact path="/checkListMaster" component={Check_List_Master} />
      <Route exact path="/causeOfEvent" component={Cause_of_Event}/>
      <Route exact path="/causeOfDeath" component={Cause_Of_Death_Master}/>
    </Router>
  }
  
}
export default App;