import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import AddLeaveRequest from '../components/add_leave_request';
import Personel_Claim from '../components/personel_claim';
import Ex_Work_Sheet from '../components/ex_work_sheet';

let loginUser="";
let userObject="";
let companyName="";
let listItems;
let clsObject;
let userType="";
class Claim_List extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ stListItems:"",isAllow:false,stLogItems:[],stWorkSheetItem:"" };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
      setClaimtoView(itemRow){
        this.setState({stLogItems:itemRow});
        this.forceUpdateHandler();
            //alert(JSON.stringify(itemRow));
      }
      setWorkSheettoView(itemRow){
        var strWorkTmp="["+JSON.stringify(itemRow)+"]";
        var jsTmpWork=JSON.parse(strWorkTmp);
        this.setState({stWorkSheetItem:jsTmpWork});
        this.forceUpdateHandler();
      }

      getExcelCsvExp(serTxt=""){
        var map=[];
        map.push({name:"curl_type",value:"getExlCsvExport"});
        map.push({name:"res_func",value:"callBackNgCSV"});
        map.push({name:"col4",value:$("#ser_col4").val()});
        map.push({name:"col6",value:$("#ser_col6").val()});
        map.push({name:"col34",value:$("#ser_col34").val()});
        map.push({name:"days_diff",value:$("#ser_days_diff").val()});
        map.push({name:"ser_status",value:$("#ser_status").val()});
        map.push({name:"searchTxt",value:$("#searchTxt").val()});
        if(userObject[0].nm){
          userType=userObject[0].nm;
       }
       map.push({name:"category_id",value:userType});
       map.push({name:"emp_id",value:userObject[0].id});
        //map.push({name:"refId",value:"42"});
          map["res_func"]="callBackNgCSV";
          map["curl_type"]="getExlCsvExport";
          CallMapMethod(clsObject,map);
      }
      callBackNgCSV(data,msg){
        //alert(data);
        /*
        var csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(data);
        var encodedUri = encodeURI(csvContent);
var link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "upload_data" + (new Date()).getTime() + ".csv");
link.click();
*/
let csvContent = atob(data);
var blob = new Blob([csvContent], {type: "data:application/octet-stream;base64"});
var url  = window.URL.createObjectURL(blob);
// you need to precise a front-end button to have a name
var link = document.createElement("a");
link.setAttribute("href", url);
let getDt=new Date();
link.setAttribute("download", "_data" + getDt.getDate()+"_"+getDt.getMonth()+"_"+getDt.getFullYear()+"_"+"_"+getDt.getHours()+"_"+getDt.getMinutes() + ".csv");
link.click();
// var dlnk = document.getElementById("a.csv");
// dlnk.href = url;
// dlnk.click();
      }
      
      getClaimData(serTxt=""){
        //alert("i m in data ="+serTxt);
        var map=[];
        map.push({name:"curl_type",value:"getClaimRequest"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"col4",value:$("#ser_col4").val()});
        map.push({name:"col6",value:$("#ser_col6").val()});
        map.push({name:"col34",value:$("#ser_col34").val()});
        map.push({name:"days_diff",value:$("#ser_days_diff").val()});
        map.push({name:"ser_status",value:$("#ser_status").val()});
        map.push({name:"searchTxt",value:$("#searchTxt").val()});
        if(userObject[0].nm){
          userType=userObject[0].nm;
       }
       map.push({name:"category_id",value:userType});
       map.push({name:"emp_id",value:userObject[0].id});
        //map.push({name:"refId",value:"42"});
          map["res_func"]="callBackNg";
          map["curl_type"]="getClaimRequest";
          CallMapMethod(clsObject,map);
        }
      callBackNg(data,msg){
        //alert(data);
         //alert(msg);
         //$("#tmpRespt").html(data);
         //return;
       if(data.trim()!=="" && data.trim()!=="0"){
          listItems=JSON.parse(data);
          this.setState({stListItems:listItems,isAllow:true},()=>{
            //RespBkMthdMapp(clsObject,"getEmpAttendance","shCollapse");
          });
          //alert(JSON.stringify(this.state.stListItems));
          this.forceUpdateHandler();
       }
   }
   getEmpAttendance(refId){
    var map=[];
    map.push({name:"curl_type",value:"getAttendanceListLog"});
    map.push({name:"res_func",value:"callBackLogNg"});
    map.push({name:"company_id",value:userObject[0].app_user_id});
    map.push({name:"non_enc_emp_id",value:refId});
    map.push({name:"month_nm",value:"11"});
    map["res_func"]="callBackLogNg";
    map["curl_type"]="getAttendanceListLog";
    CallMapMethod(this,map);
   }
   callBackLogNg(data,msg){
    
     if(data.trim()!=="" && data.trim()!=="0"){
      this.setState({stLogItems:JSON.parse(data)});
      this.forceUpdateHandler();
     }
     //alert(data);
   } 
componentDidMount(){
  // alert("i m in report");
  clsObject=this;
  this.getClaimData();
  RespBkMthdMapp(clsObject,"getClaimData","_setSearchKeyUp");
}

      render(){
          return(<div>
<Header/>
<div className="content">
  <div id="tmpRespt"></div>
<div className="container-fluid mt-4">
<div className="row">
<div className='col-lg-2 col-md-2 col-sm-4 col-2' style={{marginBottom:"10px"}}><button type="button" onClick={()=>this.getExcelCsvExp("")} class="btn btn-block btn-primary btn-sm">Export to Excel CSV</button></div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
           <div className="row">
             {/* <div className="col-lg-2 col-md-2 col-sm-4">
             <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Search</b></h3>
             </div> */}
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select onChange={()=>this.getClaimData()} className="form-control form-control-sm" id="ser_col4" name="ser_col4">
                <option value="0">-Line of Business-</option>
                <option value="INDIVIDUAL">INDIVIDUAL</option>
                <option value="GROUP">GROUP</option>
                <option value="CREDIT">CREDIT</option>
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select onChange={()=>this.getClaimData()} className="form-control form-control-sm" id="ser_col6" name="ser_col6">
                <option value="0">-Type of Claim-</option>
                <option value="DEATH">DEATH</option>
                <option value="DISABILITY">DISABILITY</option>
                <option value="CRITICAL ILLNESS">CRITICAL ILLNESS</option>
                <option value="HEALTH">HEALTH</option>
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select onChange={()=>this.getClaimData()} className="form-control form-control-sm" id="ser_col34" name="ser_col34">
                <option value="0">-Early/Non-EALRY-</option>
                <option value="EARLY">EARLY</option>
                <option value="NON-EARLY">NON-EARLY</option>
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select onChange={()=>this.getClaimData()} className="form-control form-control-sm" id="ser_status" name="ser_status">
                <option value="0">-Status-</option>
                <option value="Approved">Approved</option>
                <option value="Documents Pending">Documents Pending</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <select onChange={()=>this.getClaimData()} className="form-control form-control-sm" id="ser_days_diff" name="ser_days_diff">
                <option value="0">Days Difference</option>
                <option value="120">120 Days</option>
                <option value="90">90 Days</option>
                <option value="60">60 Days</option>
              </select>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-4">
    <div className="input-group">
                    <input autoComplete="off" type="text" id="searchTxt" name="searchTxt" className="form-control form-control-sm _setSearchKeyUp"   placeholder="Search" />
                    <div className="input-group-prepend">
                      <span className="input-group-text form-control-sm currency"><i className="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                  </div>
     
    </div>
  </div>
              </div>
                <table class="table table-bordered table-hover table-sm text-sm text-wrap table-responsive">
                  <thead>
                  <tr class="border">
                        <th>Name of Life Assured</th>
                        <th>Entry Date</th>
                        <th>Country</th>
                        <th>Type</th>
                        <th>Company Name</th>
                        <th>Line of Business</th>
                        <th>Group Name</th>
                        <th>Claim Assesor</th>
                        <th>NoOfDays</th>
                        <th>Action</th>
                 </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.isAllow==true) ? this.state.stListItems.map((item,index)=>{
                     return <tr>
<td>
    <div className="user-block">
        <img className="img-circle" src={defaultPic} alt="User Image" />
        <span className="username"><a href="#">{item.col8}</a></span>
        <span className="description">LUNA No:{item.col1} Policy No:{item.col17}</span>
    </div>
</td>
<td>{item.entry_date}</td>
<td>{item.col2}</td>
<td>{item.col6}</td>
<td>{item.col3}</td>
<td>{item.col4}</td>
<td>{item.col5}</td>

<td>{item.person_name!==""?item.person_name:item.user_name}</td>
<td>{item.days_diff}</td>
<td>
    <div class="card-tools">
    <a param1={item.emp_id} onClick={()=>clsObject.setWorkSheettoView(item)} data-toggle="modal" data-target="#exWorkSheet" class="btn btn-tool shCollapse" aria-expanded="false">
            WorkSheet
        </a>
        <a param1={item.emp_id} onClick={()=>clsObject.setClaimtoView(item)} data-toggle="modal" data-target="#viewAttendLog" class="btn btn-tool shCollapse" aria-expanded="false">
            View
        </a>
    </div>
</td>

</tr>
                  }
                  ):''
                 }
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>

<Personel_Claim logList={this.state.stLogItems} />
<Ex_Work_Sheet workSheet={this.state.stWorkSheetItem}/>
          </div>)
      }
}
export default Claim_List;