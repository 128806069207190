import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
class Check_List_Master extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
      callBackNg(data,msg){
        //  alert(data);
        if(data.trim()!=="" && data.trim()!=="0"){
          var listItems=JSON.parse(data);
          var str="";
          var countEmp=0;
        
          for(var i=0;i<listItems.length;i++){
              countEmp++;
             
            str+="<tr>"+
            "<td>"+(i+1)+"</td>"+
             "<td>"+listItems[i]["p_details"]+"</td>"+
             "<td>"+listItems[i]["details"]+"</td>"+
             "<td>"+""+"</td>"+
             "</tr>"
          }
          $("#tbData").html(str);
          $(".total_emp").html(countEmp);
        }
    }
 getCompanyMaster(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackNg"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    //map.push({name:"type_id",value:"3"});
    map["res_func"]="callBackNg";
    map["curl_type"]="getCheckList";
    CallMapMethod(this,map);
 }
 callBackParent(data,msg){
    // alert(data);
    var strParentId="<option value=\"0\">"+"-Select-"+"</option>";
    if(data.trim()!=="" && data.trim()!=="0"){
       
        var listParent=JSON.parse(data);
        for(var p=0;p<listParent.length;p++){
            strParentId+="<option value=\""+listParent[p]["id"]+"\">"+listParent[p]["details"]+"</option>";
        }
    }
    $("#parent_id").html(strParentId);
 }
 getCheckListParent(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackParent"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"parent_id",value:"0"});
    map.push({name:"type_id",value:"1"});
    map["res_func"]="callBackParent";
    map["curl_type"]="getCheckList";
    CallMapMethod(this,map);
 }   
componentDidMount(){
    clsObject=this;
 this.getCompanyMaster();
 this.getCheckListParent();
 /*
  var empCategory=[];
  empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
  empCategory.push({name:"res_func",value:"callBackEmpCate"});
  empCategory.push({name:"local_token",value:userObject[0].app_user_id});
  empCategory.push({name:"ref2_id",value:userObject[0].id});
  empCategory["res_func"]="callBackEmpCate";
  empCategory["curl_type"]="getEmployeeCategory";
  CallMapMethod(this,empCategory);
*/
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}
callBackEmpCate(data,msg){
    //alert(data);
    var listEmpCategor=JSON.parse(data);
    var strTmp="<option value=\"0\">-select-</option>";
    for(var l=0;l<listEmpCategor.length;l++){
        strTmp+="<option value=\""+listEmpCategor[l]["id"]+"\">"+listEmpCategor[l]["category_name"]+"</option>"
    }
    $("#party_mapping_id").html(strTmp);

}
okSucc(data,msg){
    //alert(data+"===="+msg);
    if(data==="001"){

        $("#frmCreate").find("input[type=text]").val("");
        //$("#frmCreate").find()
        $(".respSSL").html(msg);
        clsObject.getCompanyMaster();
    }else{
        $(".respSSL").html(msg);
    }
}
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divEmployee">Add New</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Total Groups<span className="float-right badge bg-primary total_emp"></span> </a></h6> </li>
              {/* <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Activated <span className="float-right badge bg-info">5</span> </a></h6> </li>
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Pending Profile <span className="float-right badge bg-success">12</span> </a></h6> </li>
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Deactivated <span className="float-right badge bg-danger">42</span> </a></h6> </li> */}
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Company Master</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                      <th>Parent Title</th>
                      <th>Title</th>
                       <th>Status</th> 
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Check List Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Parent Title *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id" name="parent_id" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Check List *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="Check List" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Priority Index *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="ref1_id" name="ref1_id" className="form-control" placeholder="Priority Index" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Check_List_Master;