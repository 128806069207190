import React from 'react';
class Add_Family_Details extends React.Component{
    render(){
        return(<div class="modal fade" id="famdlt">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Family Members</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="frmFami" role="form">
                        <input type="hidden" name="fam_emp_id" id="fam_emp_id" value="" />
                        <input type="hidden" name="fam_comp_id" id="fam_comp_id" value="" />
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insFamilyMem" />
                        <input type="hidden" name="m_type" id="m_type" value="insFamilyMem" />
                        <input type="hidden" name="type_id" id="type_id" value="22" />
                        <div class="card-body">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Name</label>
                                <input type="text" class="form-control" id="col1" name="col1" placeholder="Name" />
                            </div>
    
                            <div class="form-group">
                                <label for="exampleInputPassword1">DOB</label>
                                <input type="text" class="form-control datepicker" autocomplete="off" id="col2" name="col2" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Relation</label>
                                <select id="col3" name="col3" class="form-control">
                                    <option value="0">-Select-</option>
                                    <option>Brother</option>
                                    <option>Father</option>
                                    <option>Mother</option>
                                    <option>Sister</option>
                                    <option>Spouse</option>
                                </select>
                                
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Member's Occupation</label>
                                <input type="text" class="form-control" id="col4" name="col4" placeholder="Occupation" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">If Nominee</label>
                                <input type="text" class="form-control" id="col5" name="col5" placeholder="if Nominee" />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputFile">Upload Aadhar</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="exampleInputFile" />
                                        <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                    </div>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="">Upload</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn _btnSetFami btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>);
    }
}
export default Add_Family_Details;