import $ from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import {GetDateDifferecec,GetImgCertUser,CallMapMethod,drawChart,AjaxCallMethod,TableDatePicker,RespBkMthdMapp} from '../function_lib/common_lib';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import Claim_Work_Sheet from './claim_work_sheet';
import {CityList} from '../function_lib/city_india';
import Inputmask from "inputmask";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './new_claim_comp.css';

let loginUser="";
let userObject="";
let clsObject;

let groupNameList_col5="";
let causeEventList_col32="";


window.jQuery = $;
window.$ = $;
global.jQuery = $;

let checkListChk;
let arrExCheckList;
let locCityList;

let alertSucideClaim=false;
let checkListData;
class New_Claim_Comp extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };


  closeMe(clName){
    // $(clName).modal("hide");
    $(clName).hide();
  }
  showMe(clName){
    $(clName).show();
    $(clName).addClass("show");
    // $(clName).modal("show");
  }
  workSheetPendingDocs(allList,checkedList){


  }

  getOthMaster(){
    var map=[];
    map.push({name:"curl_type",value:"getLogMaster"});
    map.push({name:"res_func",value:"callBackOthMast"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"type_id",value:"2022"});
    map["res_func"]="callBackOthMast";
    map["curl_type"]="getLogMaster";
    CallMapMethod(this,map);
  }
  callBackOthMast(data,msg){
   
    var strCompnay="<option value=\"0\">-SELECT-</option>";
    var strGroupName="<option value=\"0\">-SELECT-</option>";
    var strCauseofEvent="<option value=\"0\">-SELECT-</option>";
    var strPrimaryCause="<option value=\"0\">-SELECT-</option>"
    if(data!=="0" && data!==""){
      var listMasters=JSON.parse(data);
      if(listMasters.length!=0){
        for(var m=0;m<listMasters.length;m++){
           if(listMasters[m]["type_id"]==="230"){
             strCompnay+="<option value=\""+listMasters[m]["col1"]+"\">"+listMasters[m]["col1"]+"</option>";
           }else if(listMasters[m]["type_id"]==="231"){
            strGroupName+="<option value=\""+listMasters[m]["col1"]+"\">"+listMasters[m]["col1"]+"</option>";
           }else if(listMasters[m]["type_id"]==="232"){
            strCauseofEvent+="<option value=\""+listMasters[m]["col1"]+"\">"+listMasters[m]["col1"]+"</option>";
           }else if(listMasters[m]["type_id"]==="233"){
             strPrimaryCause+="<option value=\""+listMasters[m]["col1"]+"\">"+listMasters[m]["col1"]+"</option>";
           }
        }
      }
    }
    //alert(strPrimaryCause);
    $("#col3").html(strCompnay);
    $("#col5").html(strGroupName);
    $("#col32").html(strCauseofEvent);
    $("#col99").html(strPrimaryCause);
  }
 componentWillReceiveProps(props){
  this.props=props;
//alert(JSON.stringify(props.itemRow));
if(this.props.itemRow.id){
 // alert(this.props.itemRow["id"]);
 //alert($("#col33").val());
  for(var a=1;a<=103;a++){
  
    if(document.getElementById("col"+a)){
      $("#col"+a).val(this.props.itemRow["col"+a]);
    }
    
  //  if(this.props.itemRow["col"+a]==="GROUP"){
     
  //   $("#col"+a).val(this.props.itemRow["col"+a]);
  //  }else{
  //   if(a<74){
  //     $("#col"+a).val(this.props.itemRow["col"+a]);
  //    }
  //  }
}
//alert($("#col33").val());
  $("#status").val(this.props.itemRow["status"]);
  $("#ref_id").val(this.props.itemRow["id"]);
  $("#check_list").val(this.props.itemRow["check_list"]);
  arrExCheckList=this.props.itemRow["check_list"].split(',');
  clsObject.CheckEarly();
  clsObject.placeOfEvent();
}
}

  saveClaim(){
    var fromDt=$("#col24").val();
    var toDt=$("#col31").val();
    if(fromDt.trim()==="" || toDt.trim()===""){
      return;
    }
    var arrFromDt=fromDt.split('/');
   var dtFrom = new Date(+arrFromDt[2], arrFromDt[1] - 1, +arrFromDt[0]);
   
   var arrToDt=toDt.split('/');
   var dtTo=new Date(+arrToDt[2], arrToDt[1] - 1, +arrToDt[0]);
   if(dtTo<=dtFrom){
    alert("Cause of Event is before RCD. Claim not admissable");
    return;
   }

    //alert("Testing ");
   if($("#col6").val()==="DEATH"){
     if($("#col32").val()==="SUICIDE"){
      
    var getDays=GetDateDifferecec(fromDt,toDt);
    if(getDays<=365){
      if(window.confirm("Do you want to proceed, Cause of death selected as Suicide and policy duration is less than 1 year. Kindly decision accordingly")===true){

      }else{
       return;
      }
    
      //return;
    }
     }
   }

    GetImgCertUser(clsObject,"#frmClaimReq","addEditClaim","callBackClaim");
  }
  saveGenerateWork(){
    var fromDt=$("#col24").val();
    var toDt=$("#col31").val();
    if(fromDt.trim()==="" || toDt.trim()===""){
      return;
    }
    var arrFromDt=fromDt.split('/');
   var dtFrom = new Date(+arrFromDt[2], arrFromDt[1] - 1, +arrFromDt[0]);
   
   var arrToDt=toDt.split('/');
   var dtTo=new Date(+arrToDt[2], arrToDt[1] - 1, +arrToDt[0]);
   if(dtTo<=dtFrom){
    alert("Cause of Event is before RCD. Claim not admissable");
    return;
   }

    if($("#col6").val()==="DEATH"){
      if($("#col32").val()==="SUICIDE"){
      
     var getDays=GetDateDifferecec(fromDt,toDt);
     if(getDays<=365){
       if(window.confirm("Do you want to proceed, Cause of death selected as Suicide and policy duration is less than 1 year. Kindly decision accordingly")===true){

       }else{
        return;
       }
      
     }
      }
    }
    GetImgCertUser(clsObject,"#frmClaimReq","addClaimwithWorkSheet","callBackGenerate");
  }
  tmpGenerateWork(){
    var checkWrk='[{"id":"42","col1":"7895858","col2":"India","col3":"MAX NEW YORK LIFE INSURANCE","col4":"INDIVIDUAL","col5":"MANIPAL TECHNOLOGIES LTD","col6":"DEATH","col7":"CONCURRENCE","col8":"Shailendra Prajapati","col9":"13/12/2021","col10":"30","col11":"Male","col12":"Graduate","col13":"Salaried","col14":"Married","col15":"650000","col16":"Mumbai","col17":"456858","col18":"Term","col19":"Term","col20":"PPT","col21":"Bancassurance","col22":"85000","col23":"85000","col24":"13/12/2021","col25":"Yes","date":"","entry_date":"12/13/2021 8:06:30 PM","type_id":"1001","status":"","profile_pic":"","app_user_id":"631","col26":"13/12/2021","col27":"Medical","col28":"UW Notes","col29":"13/12/2021","col30":"20","col31":"13/12/2021","col32":"COVID-19","col33":"Mumbai","col34":"","col35":"13/12/2021","col36":"85000","col37":"8500","col38":"8500","col39":"","col40":"","col41":"","col42":"","col43":"","col44":"","col45":"","col46":"","col47":"Yes","col48":"1. Investigation Findings","col49":"1. Re-UW Opinion","col50":"1. MR Recommendation","col51":"SETTLE","col52":"ADMIT","col53":"13/12/2021","col54":"13/12/2021","col55":"13/12/2021","col56":"13/12/2021","col57":"13/12/2021","col58":"13/12/2021","col59":"13/12/2021","col60":"13/12/2021","col61":"13/12/2021","col62":"13/12/2021","col63":"13/12/2021","col64":"","col65":"Santosh Nair","col66":"","col67":"","col68":"","col69":"","col70":""}]';
    var workSheetD=JSON.parse(checkWrk);
    this.setState({stWorkSheet:workSheetD});
    this.forceUpdateHandler();
    this.showMe("#claimWorkSheet");
  }
  callBackGenerate(data,datares,userResp){
    //alert(data+datares);
    
if(data==="not_validated"){
  alert("Kindly fill mendatory values !!");
  return;
}
    if(data!=="0" && data!==""){
      alert("Record Added Successfully");
      var workSheetD=JSON.parse(data);
      if($("#ref_id").val()==="0"){
        $("#refresh_id").val(workSheetD[0]["id"]);
      }
       
      $("#ref_id").val(workSheetD[0]["id"]);
    
      this.setState({stWorkSheet:workSheetD});
      this.forceUpdateHandler();
      this.showMe("#claimWorkSheet");
     
      
      //window.location.reload();
    }
  }

  callBackClaim(data,datares,userResp){
    //alert(data+datares);
    if(data==="not_validated"){
      alert("Kindly fill mendatory values !!");
      return;
    }
    if(data==="100"){
      //$("#frmClaimReq").find("input[type=text]").val("");
      alert("Record Added Successfully");
      window.location.reload();
    }else{
      alert("Unable to Add Record !!");
    }
   
  }


  getCheckList(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackChk"});
    //map.push({name:"refId",value:"42"});
      map["res_func"]="callBackChk";
      map["curl_type"]="getCheckList";
      CallMapMethod(clsObject,map);
  }
  callBackChk(data,msg){
    //alert(data+msg);
    if(data!=="" && data!=="0"){
      checkListChk=JSON.parse(data);
      //clsObject.findOutCheckList();
    }
  }

  placeOfEvent(){
    var checkPlace=$("#col33").val();
    //alert(checkPlace);
    if(checkPlace.trim()=="Hospital"){
      document.getElementById("col101").style.display="block";
    }else{
      document.getElementById("col101").style.display="none";
    }
  }

  CheckPolicyDur(){
    var firstDt=$("#col24").val();
    var lastDt=$("#col31").val();
    if(firstDt.trim()==="" || lastDt.trim()===""){
      return;
    }
    let getDiffDays=GetDateDifferecec(lastDt,firstDt);
    $("#col30").val(getDiffDays);
  }

  CheckEndToEndDur(){
    var firstDt=$("#col53").val();
    var lastDt=$("#col62").val();
    if(firstDt.trim()==="" || lastDt.trim()===""){
      return;
    }
    var getDiffDays=GetDateDifferecec(lastDt,firstDt);
    $("#col64").val(getDiffDays);

  }
  
  ReinsuranceAmt(){
    let totalClmAmt=$("#col44").val();
    let retentionAmt=$("#col45").val();
    if(totalClmAmt.trim()!=="" && retentionAmt.trim()!==""){
       $("#col46").val(totalClmAmt-retentionAmt);
    }
  }
  SetCountryCurrency(){
    let selCountry=$("#col2").val();
    let strCurrency="INR";
    if(selCountry.trim()==="India"){
      strCurrency="INR";
    }else if(selCountry.trim()==="Srilanka"){
      strCurrency="LKR";
    }else if(selCountry.trim()==="Nepal"){
      strCurrency="NPR";
    }
    $(".currency").html(strCurrency);
    $("#col66").val(strCurrency);
    clsObject.setStateForGroup();
  }
  CheckDOB(){
    let dobInp=$("#col9").val();
    let doeInp=$("#col31").val();
    if(dobInp==="" || doeInp===""){
      return;
    }
    var getDays=GetDateDifferecec(dobInp,doeInp);
    let strDiff="";
    let tmpDiffDays=parseInt(getDays);
   // alert(tmpDiffDays)
    let nosCount=0;
    let monthCount=0;
    if(tmpDiffDays>=365){
       nosCount=parseInt(tmpDiffDays/365);
       tmpDiffDays=tmpDiffDays%365;
    }
    if(tmpDiffDays>=30){
      monthCount=parseInt(tmpDiffDays/30);
      tmpDiffDays=tmpDiffDays%30;
    }
    if(monthCount>6){
      nosCount++;
    }
    $("#col10").val(nosCount);
   
  }

  CheckEarly(exCheckList=""){
    //alert("i m in focus out");
    var dtFrom=$("#col24").val();
    var dtTo=$("#col31").val();
    if(dtFrom.trim()==="" || dtTo.trim()===""){
      return;
    }
    var getDays=GetDateDifferecec(dtFrom,dtTo);
    //alert("testing=="+getDays);
    var compWith=365*3;
    var checkEarly="EARLY";
    if(getDays>compWith){
      checkEarly="NON-EARLY";
    }
    $("#col34").val(checkEarly);

    //-- diff in year/month/days
    let strDiff="";
    let tmpDiffDays=parseInt(getDays);
   // alert(tmpDiffDays)

    if(tmpDiffDays>=365){
       var nosCount=parseInt(tmpDiffDays/365);
       strDiff=nosCount+" Years";
       tmpDiffDays=tmpDiffDays%365;
    }
    if(tmpDiffDays>=30){
      var nosCount=parseInt(tmpDiffDays/30);
      if(strDiff!==""){
        strDiff+=",";
      }
      strDiff+=nosCount+" Months";
      tmpDiffDays=tmpDiffDays%30;
    }
    if(tmpDiffDays>0){
      if(strDiff!==""){
        strDiff+=",";
      }
      strDiff+=tmpDiffDays+" Days";
    }
   if(getDays==="0"){
    strDiff=getDays;
   }
    $("#col30").val(strDiff);
    clsObject.findOutCheckList();
    clsObject.CheckDOB();
    //clsObject.CheckPolicyDur();
  }
  setCountryForGroup(){
   
  }
  setStateForGroup(){
    
    let forGroup= $("#col4").val();
    let forCountry=$("#col2").val();
      this.setState({stGroup:forGroup,stCountry:forCountry},()=>{
        $('[data-mask]').inputmask();
      });
      this.forceUpdateHandler();
    
  }

  findOutCheckList(){
    this.setStateForGroup();
     var lineofBusiness=$("#col4").val();
     var typeOfClaim=$("#col6").val();
     var earlyOrNot=$("#col34").val();
     var causeofEvent=$("#col32").val();
     if(lineofBusiness==="0"){
       return;
     }
     if(typeOfClaim==="0"){
       return;
     }
     if(earlyOrNot.trim()===""){
       return;
     }
     if(causeofEvent.trim()==="0"){
      return;
     }

    
     var mapCheck=[];
     mapCheck.push({name:"curl_type",value:"getLogMaster"});
     mapCheck.push({name:"res_func",value:"callBackFind"});
     mapCheck.push({name:"col1",value:lineofBusiness});
     mapCheck.push({name:"col2",value:typeOfClaim});
     mapCheck.push({name:"col3",value:earlyOrNot});
     mapCheck.push({name:"col4",value:causeofEvent});
     //map.push({name:"refId",value:"42"});
     mapCheck["res_func"]="callBackFind";
     mapCheck["curl_type"]="getLogMaster";
       CallMapMethod(clsObject,mapCheck);
  }
  callBackFind(data,msg){
    //Prepare CheckList Data Here
    //alert(msg);

    let exCheckList11="";

    var strMainList="<table>";
    var strChildList="";
    var chkBoxCode="";
    var cPId=0;
    var tmpCPID=0;
    if(data!=="" && data!=="0"){
      var getRequiredChkList=JSON.parse(data);
      if(getRequiredChkList.length==0){
        return;
      }
      var getChkStr=getRequiredChkList[0]["details"];
      var arrChk=getChkStr.split(',');
      //alert(checkListChk.length);
      for(var c=0;c<checkListChk.length;c++){
        var exChecked="";
        if(arrExCheckList){
          if(arrExCheckList.length>0){
            if(arrExCheckList.indexOf(checkListChk[c]["id"])!=-1){
              exChecked="checked";
            }
          }
        }
       
          if(arrChk.indexOf(checkListChk[c]["id"])!=-1){
            tmpCPID=checkListChk[c]["parent_id"];
            if(cPId!=tmpCPID){
              strChildList+="<tr><td colspan=\"2\"><b>"+checkListChk[c]["p_details"]+"</b></td></tr>"
            }
            if(exChecked===""){
              exCheckList11+=checkListChk[c]["details"]+"<br></br>";
            }

            chkBoxCode="<input "+exChecked+" class=\"chkBox\" type=\"checkbox\" value=\""+checkListChk[c]["id"]+"\" name=\""+checkListChk[c]["id"]+"\" id=\""+checkListChk[c]["id"]+"\" />"+
                       "<label style=\"font-weight:0\" for=\""+checkListChk[c]["id"]+"\">"+checkListChk[c]["details"]+"</label>";
            strChildList+="<tr><td></td><td>"+chkBoxCode+"</td></tr>";
            strMainList+=strChildList;
            cPId=checkListChk[c]["parent_id"];
            strChildList="";
          }
         
      }
      $("#checkListCW1").html(exCheckList11);
    }
    strMainList+="</table>";
    //alert(strMainList);
    $("#activeChkList").html(strMainList);
    RespBkMthdMapp(clsObject,"assignCheckList","chkBox")
  }
  assignCheckList(){
    var chkCheckList=document.getElementsByClassName("chkBox");
    //alert("I am in assign Checklist "+chkCheckList.length);
    var strChkList="";
    for(var ci=0;ci<chkCheckList.length;ci++){
      if(chkCheckList[ci].checked){
         if(strChkList!==""){
           strChkList+=",";
         }
         strChkList+=chkCheckList[ci].value;
      }
      $("#check_list").val(strChkList);
      //alert(chkCheckList[ci].checked+","+chkCheckList[ci].value);
    }
  }

  componentDidMount(){
   // alert(locCityList.length);
   clsObject=this;
   var arrGroupNmList=groupNameList_col5.split(',');
   var strGrpList="<option value=\"0\">-SELECT-</option>";
   for(var g=0;g<arrGroupNmList.length;g++){
       strGrpList+="<option value=\""+arrGroupNmList[g]+"\">"+arrGroupNmList[g]+"</option>"
   }
   $("#col5").html(strGrpList);
   //$('[data-mask]').inputmask();
    //$('#col53').Inputmask();

   $("#ref_id").val("0");
   $("#refresh_id").val("0");
   this.getCauseofEventList();
   this.getCheckList();
   this.getOthMaster();

   locCityList=CityList();
   $("#col16").autocomplete({
    source:locCityList
  });

  }

  getCauseofEventList(){
    var arrCauseList=causeEventList_col32.split(',');
    var strGrpList="<option value=\"0\">-SELECT-</option>";
    for(var g=0;g<arrCauseList.length;g++){
        strGrpList+="<option value=\""+arrCauseList[g]+"\">"+arrCauseList[g]+"</option>"
    }
    $("#col32").html(strGrpList);
  }

  constructor(props){
    super(props);
    locCityList=CityList();
    if(localStorage.getItem("userData")!=null){
      userObject= getUserObjData();
  }

  this.state={ stWorkSheet:"",stGroup:"",stCountry:""};
  this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

  groupNameList_col5="TOWER LTD, INFOGAIN INDIA PVT LTD, INSOLVENCY AND BANKRUPTCY BOARD OF INDIA, INTERGLOBE AVIATION, IVY SOFTWARE DEVELOPMENT SVCS PVT LTD, J P  MORGAN SVCS INDIA PVT LTD, KLA TENCOR SOFTWARE INDIA PVT LTD, KPMG ASSURANCE AND CONSULTING PRACTICES, LG ELECTRONICS INDIA PVT LTD, MANIPAL TECHNOLOGIES LTD, MEDIA.NET SOFTWARE SVCS, METSO INDIA PVT LTD, MIND TREE, NEKTRA THERAPEUTICS INDIA, NEXTGEN HEALTH CARE INDIA PVT LTD, NUANCE INDIA LTD, NUCLEUS SOFTWARE EXPORTS LTD, NXP INDIA PVT LTD, OGILVY & MATHERS, OPTUM GLOBAL SOLUTIONS (I) PVT LTD, PANASONIC LIFE SOLUTIONS INDIA PVT LTD, PAYPAL INDIA PVT LTD, PFIZER PRODUCTS INDIA PVT LTD, PHILIPS INDIA LTD, PNB HOUSING FINANCE LTD, PRISM JOHNSON LTD, RELIANCE NIPPON LIFE ASSET MGMT, RENEW POWER PVT LTD, SALES FORCE INDIA PVT LTD, SAP LABS INDIA PVT LTD, SAVE FINANCIAL SVCS PVT LTD, SCHINDLER INDIA PVT LTD, SECURONIX INDIA PVT LTD, SKODA AUTO VOLKSWAGEN INDIA PVT LTD, SMCC CONSTRUCTION INDIA LTD, SOBEK AUTO INDIA PVT LTD, STRYKER GLOBAL TECHNOLOGY, TATA AIA LIFE INSURANCE COMPANY LTD, TATA SKY LTD, THOUGHTWORKS TECHNOLOGIES INDIA PVT LTD, TOWER RESEARCH CAPITAL INDIA PVT LTD, TRANSUNION GLOBAL TECHNOLOGY CENTER, UBS BUSINESS SOLUTIONS INDIA PVT LTD, VARUN BEVERAGES LTD, VEDANTA LTD (CAIRN OIL & GAS), VOLVO GROUP INDIA PVT LTD, WABCO, WM GLOBAL TECHNOLOGY SVCS PVT LTD, YES BANK LTD, ZEE ENTERTAINMENT ENTERPRISES LTD";
  causeEventList_col32="COVID-19, ACCIDENT, ACCIDENT - OTHERS, ACCIDENT - RTA, ACUTE MI, ARDS, ACUTE VIRAL PNEUMONIA WITH COVID-19, ARDS WITH COVID-19, CANCER, CORONARY ARTERY DISEASE, CARDIAC ARREST, CARDIO PULMONARY ARREST, CARDIO RESPIRATORY ARREST, CARDIO RESPIRATORY FAILURE, CARDIOGENIC SHOCK, CHRONIC LIVER DISEASE, CHRONIC KIDNEY DISEASE, HEART ATTACK, ISCHEMIC HEART DISEASE, MURDER, NATURAL DEATH, NON HODGKIN LYMPHOMA, LYMPHOMA, NOT KNOWN/ NOT SPECIFIED, SEPTIC SHOCK WITH MULTI ORGAN FAILURE/ DYSFUNCTION, SUICIDE, SUDDEN DEATH, OTHERS";
  }


    render(){
        return(
            <div id="it_declaration" className="row active tab-pane">
    <form id="frmClaimReq" enctype="multipart/form-data" method="POST" action="#">
      <input type="hidden" id="app_user_id" name="app_user_id" value={userObject[0].id} />
      <input type="hidden" id="ref_id" name="ref_id" />
      <input type="hidden" id="refresh_id" name="refresh_id"/>
      <input className='required12' type="hidden" id="check_list" name="check_list" />
      <input type="hidden" id="col66" name="col66" />
     
<div className="col-12">
  <div className="card">
    {(this.props.itemRow && this.props.itemRow["id"])?'':<div className="card-header bg-gradient-cyan">
      <h5><i className="fa fa-check"></i> New Claim Request</h5>
    </div>}
  
    <div className="row">
      <div className="card-body">
        <h5>Basic Information</h5>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">LUNA No</label>
              <input autoComplete="off" type="text" id="col1" name="col1" className="form-control required"   placeholder="LUNA No." />
              <small id="emailHelp" className="form-text text-muted">Provided by Claim Provider Company</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Country</label>
              <select onChange={()=>this.SetCountryCurrency()} className="form-control required" id="col2" name="col2">
                                                    <option value="0">-SELECT-</option>
                                                 <option value="India">India</option>
                                                 <option value="Srilanka">Srilanka</option>
                                                 <option value="Nepal">Nepal</option>
                                                </select>
              
              <small id="emailHelp" className="form-text text-muted">Country</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Company Name</label>
              <select className="form-control required" id="col3" name="col3">
                                                    <option value="0">-SELECT-</option>
                                                 <option value="ICICI PRUDENTIAL LIFE INSURANCE">ICICI PRUDENTIAL LIFE INSURANCE</option>
                                                 <option value="HDFC LIFE INSURANCE">HDFC LIFE INSURANCE</option>
                                                 <option value="BAJAJ LIFE INSURANCE">BAJAJ LIFE INSURANCE</option>
                                                 <option value="MAX NEW YORK LIFE INSURANCE">MAX NEW YORK LIFE INSURANCE</option>
                                                 <option value="AEGON LIFE">AEGON LIFE</option>
                                                 <option value="ADITYA BIRLA SUN LIFE INSURANCE">ADITYA BIRLA SUN LIFE INSURANCE</option>
                                                 <option value="EXIDE LIFE INSURANCE">EXIDE LIFE INSURANCE</option>
                                                 <option value="PNB METLIFE">PNB METLIFE</option>
                                                 <option value="AVIVA LIFE INSURANCE">AVIVA LIFE INSURANCE</option>
                                                 <option value="BHARTI AXA LIFE INSURANCE">BHARTI AXA LIFE INSURANCE</option>
                                                 <option value="CANARA HSBC LIFE INSURANCE">CANARA HSBC LIFE INSURANCE</option>
                                                 <option value="INDIA FIRST LIFE INSURANCE">INDIA FIRST LIFE INSURANCE</option>
                                                 <option value="KOTAK LIFE INSURANCE">KOTAK LIFE INSURANCE</option>
                                                 <option value="MAHALAXMI LIFE INSURANCE">MAHALAXMI LIFE INSURANCE</option>
                                                 <option value="RELIANCE NIPPON LIFE INSURANCE">RELIANCE NIPPON LIFE INSURANCE</option>
                                                 <option value="SBI LIFE INSURANCE">SBI LIFE INSURANCE</option>
                                                 <option value="TATA AIA LIFE INSURANCE">TATA AIA LIFE INSURANCE</option>
                                                 <option value="SUD LIFE INSURANCE">SUD LIFE INSURANCE</option>
                                                 <option value="LIC OF INDIA">LIC OF INDIA</option>
                                                </select>
              <small id="emailHelp" className="form-text text-muted">Company Name where claim requested</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Line of Business</label>
              <select onChange={()=>this.findOutCheckList()} className="form-control required" id="col4" name="col4">
                <option value="0">-SELECT-</option>
                <option value="INDIVIDUAL">INDIVIDUAL</option>
                <option value="GROUP">GROUP</option>
                <option value="CREDIT">CREDIT</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Group Name</label>
              <select className="form-control" id="col5" name="col5">
                <option value="0">-SELECT-</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Group Name if Applicable</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Type of Claim</label>
              <select onChange={()=>this.findOutCheckList()} className="form-control required" id="col6" name="col6">
                <option value="0">-SELECT-</option>
                <option value="DEATH">DEATH</option>
                <option value="DISABILITY">DISABILITY</option>
                <option value="CRITICAL ILLNESS">CRITICAL ILLNESS</option>
                <option value="HEALTH">HEALTH</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Death,Accident etc</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Reason for reference to MRIB</label>
              <select className="form-control required" id="col7" name="col7">
                <option value="0">-SELECT-</option>
                <option value="CONCURRENCE">CONCURRENCE</option>
                <option value="OPINION">OPINION</option>
              </select>
              <small id="emailHelp" className="form-text text-muted"></small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{ this.state.stGroup==="GROUP" ?
<div>
<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
      <div className="col-lg-12 col-md-12 col-12">Group Details</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
        <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">EMP ID</label>
              <input autoComplete="off" type="text" id="col74" name="col74" className="form-control required" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">EMP ID</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Designation</label>
              <input autoComplete="off" type="text" id="col75" name="col75" className="form-control required" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">Desgination of Employee</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Scheme Type</label>
              
  <select className="form-control" id="col76" name="col76">
                <option value="0">-SELECT-</option>
                <option value="Takeover">Takeover</option>
                <option value="Existing  or Fresh">Existing  or Fresh</option>
              </select>
              <small id="emailHelp" className="form-text text-muted"></small>Scheme Type </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Type of Cover</label>
              
  <select className="form-control" id="col77" name="col77">
                <option value="0">-SELECT-</option>
                <option value="Affinity">Affinity</option>
                <option value="Compulsory E-E">Compulsory E-E</option>
                <option value="Voluntary">Voluntary</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Type of Cover</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">DOJ</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col78" name="col78" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="mm/dd/yyyy" data-mask />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Member joining the group date</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Renewal Date</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col79" name="col79" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="mm/dd/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of renewal of the group</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Mid Term Take Over</label>
              
        
  <select className="form-control" id="col80" name="col80">
                <option value="0">-SELECT-</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Mid Term Take Over</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">FCL</label>
              
 <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col81" name="col81" className="form-control required"   placeholder="FCL" />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Free cover limit for the group</small> </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
{this.state.stCountry=="Srilanka"?<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
      <div className="col-lg-12 col-md-12 col-12">Group Details ext for SRILANKA</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
        <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Additional DB</label>
              <input autoComplete="off" type="text" id="col82" name="col82" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">Additional DB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Term rider or LTA </label>
              <input autoComplete="off" type="text" id="col83" name="col83" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">Term rider or LTA </small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Whole Life rider( Suvarnajayanti)</label>
              <input autoComplete="off" type="text" id="col84" name="col84" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted"></small>Whole Life rider( Suvarnajayanti) </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">TPD (Accident or Sickness)</label>
                       
 <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col85" name="col85" className="form-control"   placeholder="FCL" />
                  </div>
             
             
              <small id="emailHelp" className="form-text text-muted">TPD (Accident or Sickness)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">TPD(Accident only)</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col86" name="col86" className="form-control"   placeholder="FCL" />
                  </div>
            
              
              <small id="emailHelp" className="form-text text-muted">TPD(Accident only)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">PPD(Accident only)</label>
              <input autoComplete="off" type="text" id="col87" name="col87" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">PPD(Accident only)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">WOP ( Death)</label>
              <input autoComplete="off" type="text" id="col88" name="col88" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">WOP ( Death)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">WOP(TPD)</label>
              <input autoComplete="off" type="text" id="col89" name="col89" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">WOP(TPD)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">ADDB( accidental death and disability benefit)</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col90" name="col90" className="form-control"   placeholder="FCL" />
                  </div>
             
              <small id="emailHelp" className="form-text text-muted">ADDB( accidental death and disability benefit)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">FIB (Death)</label>
              <input autoComplete="off" type="text" id="col91" name="col91" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">FIB (Death)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">FIB(TPD-A/S)</label>
              <input autoComplete="off" type="text" id="col92" name="col92" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">FIB(TPD-A/S)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Funeral expense benefit</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col93" name="col93" className="form-control"   placeholder="FCL" />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Funeral expense benefit</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">SB ( Surgical Benefit)</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col94" name="col94" className="form-control"   placeholder="FCL" />
                  </div>
             
              
              <small id="emailHelp" className="form-text text-muted">SB ( Surgical Benefit)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Reimbursement benefit</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col95" name="col95" className="form-control"   placeholder="FCL" />
                  </div>
            
              
              <small id="emailHelp" className="form-text text-muted">Reimbursement benefit</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Terminal Illness</label>
              <input autoComplete="off" type="text" id="col96" name="col96" className="form-control" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">Terminal Illness</small> </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>:''

}
</div>:''}

<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">DLA Profile</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
        <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Name of Life Assured</label>
              <input autoComplete="off" type="text" id="col8" name="col8" className="form-control required" style={{textTransform:"uppercase"}}  placeholder="..." />
              <small id="emailHelp" className="form-text text-muted">Name of Person where claim registerd for</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of Birth</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input onBlur={()=>this.CheckDOB()} type="text" id="col9" name="col9" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Date of Birth</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Age</label>
              <input readOnly="true" autoComplete="off" type="text" id="col10" name="col10" className="form-control"   placeholder="Age" />
              <small id="emailHelp" className="form-text text-muted">Age</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Gender</label>
              <select className="form-control" id="col11" name="col11">
                <option value="0">-SELECT-</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Transgender">Transgender</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Gender</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Education</label>
              <select className="form-control" id="col12" name="col12">
                <option value="0">-SELECT-</option>
                <option value="Graduate">Graduate</option>
                <option value="Post Graduate">Post Graduate</option>
                <option value="Matric">Matric</option>
                <option value="Higher Secondary">Higher Secondary</option>
                <option value="Non Matric">Non Matric</option>
                <option value="Illiterate">Illiterate</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Education</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Occupation</label>
              
  <select className="form-control" id="col13" name="col13">
                <option value="0">-SELECT-</option>
                <option value="Salaried">Salaried</option>
                <option value="Self Employed">Self Employed</option>
                <option value="None">None</option>
              </select>
              
              <small id="emailHelp" className="form-text text-muted">Occupation</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Marital Status</label>
              <select className="form-control" id="col14" name="col14">
                <option value="0">-SELECT-</option>
                <option value="Married">Married</option>
                <option value="Unmarried">Unmarried</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Marital Status</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Annual Income</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col15" name="col15" className="form-control"   placeholder="Annual Income" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Annual Income</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Pincode</label>
              <input autoComplete="off" type="number" id="col97" name="col97" className="form-control" placeholder="Pincode" />
              <small id="emailHelp" className="form-text text-muted">Pincode</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Location</label>
              <input autoComplete="off" type="text" id="col16" name="col16" className="form-control"   placeholder="Location" />
              <small id="emailHelp" className="form-text text-muted">Location</small> </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">Policy Details</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
       
        <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Policy No</label>
              <input autoComplete="off" type="text" id="col17" name="col17" className="form-control"   placeholder="Policy No" />
              <small id="emailHelp" className="form-text text-muted">Policy No</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Proposal Form Sign Date</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Plan</label>
              <input autoComplete="off" type="text" id="col18" name="col18" className="form-control"   placeholder="Plan" />
              <small id="emailHelp" className="form-text text-muted">Plan</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Policy Term</label>
              <input autoComplete="off" type="text" id="col19" name="col19" className="form-control"   placeholder="Policy Term" />
              <small id="emailHelp" className="form-text text-muted">Policy Term</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Nominee Name</label>
              <input autoComplete="off" type="text" id="col67" name="col67" className="form-control"   placeholder="Nominee Name" />
              <small id="emailHelp" className="form-text text-muted">Nominee Name</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Nominee Relation</label>
              <select className="form-control" id="col68" name="col68">
                <option value="0">-SELECT-</option>
                <option value="Husband">Husband</option>
                <option value="Wife">Wife</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Newphew">Nephew</option>
                <option value="Niece">Niece</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Other">Other</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Nominee Relation</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">PPT</label>
              <input autoComplete="off" type="number" id="col20" name="col20" className="form-control"   placeholder="PPT" />
              <small id="emailHelp" className="form-text text-muted">PPT</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Channel</label>
              
              <select className="form-control" id="col21" name="col21">
                <option value="0">-SELECT-</option>
                <option value="Agency">Agency</option>
                <option value="Bancassurance">Bancassurance</option>
                <option value="Broker">Broker</option>
                <option value="Online">Online</option>
                <option value="Other">Other</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Channel</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Premium Amount</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col22" name="col22" className="form-control"   placeholder="Premium Amount" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Premium Amount</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Premium Mode</label>
              <select id="col23" name="col23" className="form-control">
                    <option value="0">-SELECT-</option>
                    <option value="Annual">Annual</option>
                    <option value="Half Yearly">Half Yearly</option>
                    <option value="Quaterly">Quaterly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Single Premium">Single Premium</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Premium Mode</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Risk Commencement Date</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" onBlur={()=>this.CheckEarly()} id="col24" name="col24" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Risk Commencement Date</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Reinstatment</label>
              
  <select className="form-control" id="col25" name="col25">
                <option value="0">-SELECT-</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Reinstatment</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Reinstatement Date</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col26" name="col26" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Reinstatement Date</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Medical/ Non Medical</label>
              
  <select className="form-control" id="col27" name="col27">
                <option value="0">-SELECT-</option>
                <option value="Medical">Medical</option>
                <option value="Non Medical">Non Medical</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Medical/ Non Medical</small> </div>
          </div>
       
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Paid to Date</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col29" name="col29" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Paid to Date</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">UW Notes</label>
              
<textarea id="col28" name="col28" className="form-control textarea" rows="4">

</textarea>
              
              <small id="emailHelp" className="form-text text-muted">UW Notes</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Policy Duration (RCD to DOE)</label>
              <input readOnly="true" autoComplete="off" type="text" id="col30" name="col30" className="form-control" placeholder="Policy Duration (RCD to DOE)" />
              <small id="emailHelp" className="form-text text-muted">(RCD to DOE)</small> </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>
<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">Claim Details</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
        <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of Event</label>
              <br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" onBlur={()=>this.CheckEarly()} id="col31" name="col31" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of Event</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Cause of Event</label>
              
  <select onChange={()=>this.findOutCheckList()} className="form-control" id="col32" name="col32">
                <option value="0">-SELECT-</option>

              </select>
              <small id="emailHelp" className="form-text text-muted">Cause of Event</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Primary Cause of Death</label>
              
  <select className="form-control" id="col99" name="col99">
                <option value="0">-SELECT-</option>

              </select>
              <small id="emailHelp" className="form-text text-muted">Primary Cause of Death</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Secondary Cause of Death</label>
              <input type="text" className="form-control" id="col100" name="col100" />
              <small id="emailHelp" className="form-text text-muted">Secondary Cause of Death</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Place of Event</label>
              <select onChange={()=>this.placeOfEvent()} id="col33" name="col33" type="text" className="form-control">
                  <option value="0">-SELECT-</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Home">Home</option>
                  <option value="Other">Other</option>
              </select>
              <input style={{display:"none"}} autoComplete="off" id="col101" name="col101" type="text" className="form-control"   placeholder="Name of Hospital" />
              <small id="emailHelp" className="form-text text-muted">Place of Event</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Early/ Non - Early</label>
              <input autoComplete="off" id="col34" name="col34" readOnly="true" type="text" className="form-control"   placeholder="" />
              <small id="emailHelp" className="form-text text-muted">Early/ Non - Early</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date Claim reported to Cedant</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col35" name="col35" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date Claim reported to Cedant</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Base Sum Assured</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="number" id="col36" name="col36" className="form-control required"   placeholder="Base Sum Assured" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Base Sum Assured</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">ADB</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" id="col37" name="col37" type="text" className="form-control"   placeholder="ADB" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">ADB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Critical Illness</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" id="col38" name="col38" type="text" className="form-control"   placeholder="Critical Illness" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Critical Illness</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">SCB</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="text" id="col39" name="col39" className="form-control"   placeholder="SCB" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">SCB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">TPD</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="text" id="col40" name="col40" className="form-control"   placeholder="TPD" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">TPD</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">HCB</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" type="text" id="col41" name="col41" className="form-control"   placeholder="HCB" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">HCB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Major Surgery Benefit</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" id="col42" name="col42" type="text" className="form-control"   placeholder="Major Surgery Benefit" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Major Surgery Benefit</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Fund Value/WOP (if applicable/available)</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" id="col43" name="col43" type="text" className="form-control"   placeholder="Fund Value/WOP (if applicable/available)" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Fund Value/WOP (if applicable/available)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Total Claim Amount Payable</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" onBlur={()=>this.ReinsuranceAmt()} id="col44" name="col44" type="number" className="form-control"   placeholder="Total Claim Amount Payable" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Total Claim Amount Payable</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Retention</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input autoComplete="off" onBlur={()=>this.ReinsuranceAmt()} id="col45" name="col45" type="number" className="form-control"   placeholder="Retention" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Retention</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Reinsurance Amount</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text currency">INR</span>
                    </div>
                    <input readOnly="true" autoComplete="off" id="col46" name="col46" type="number" className="form-control"   placeholder="Reinsurance Amount" />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Reinsurance Amount</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Claimant</label>
              <input  autoComplete="off" type="text" id="col70" name="col70" className="form-control" placeholder="Claimant" />
              <small id="emailHelp" className="form-text text-muted">Claimant</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Claimant KYC</label>
              <input  autoComplete="off" type="text" id="col71" name="col71" className="form-control" placeholder="Claimant KYC" />
              <small id="emailHelp" className="form-text text-muted">Claimant KYC</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Claim Summary</label>
              <textarea id="col69" name="col69" className="form-control textarea" rows="4">

</textarea>
             
              
              <small id="emailHelp" className="form-text text-muted">Claim Summary</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Investigations</label>
              
  <select className="form-control" id="col47" name="col47">
                <option value="0">-SELECT-</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Investigations</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Investigation Findings</label>
              <textarea id="col48" name="col48" className="form-control textarea" rows="4">

</textarea>
             
              
              <small id="emailHelp" className="form-text text-muted">Investigation Findings</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
               <div className="form-group">
               <label for="exampleInputEmail1">RI Account Confirmation</label>
               <textarea id="col65" name="col65" className="form-control textarea" rows="4">

</textarea>
               
               </div>
          </div>
          
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Re-UW Opinion</label>
              <textarea id="col49" name="col49" className="form-control textarea" rows="4">

</textarea>
            
              <small id="emailHelp" className="form-text text-muted">Re-UW Opinion</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">MR Recommendation</label>
              <textarea id="col50" name="col50" className="form-control textarea" rows="4">

              </textarea>
              
              <small id="emailHelp" className="form-text text-muted">MR Recommendation</small> </div>
          </div>
        
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Industry Check</label>
              <textarea id="col72" name="col72" className="form-control textarea" rows="4">

              </textarea>
              
              <small id="emailHelp" className="form-text text-muted">Industry Check</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Internet Check</label>
              <textarea id="col73" name="col73" className="form-control textarea" rows="4">

              </textarea>
              
              <small id="emailHelp" className="form-text text-muted">Internet Check</small> </div>
          </div>
        
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Recommendation by Cedant</label>
              <select id="col51" name="col51" className="form-control">
                <option value="0">-SELECT-</option>
                <option value="SETTLE">SETTLE</option>
                <option value="REJECT">REJECT</option>
                <option value="REPUDIATE">REPUDIATE</option>
                <option value="REPUDIATE">REQUIREMENT</option>
                <option value="NONE">NONE</option>

              </select>
              <small id="emailHelp" className="form-text text-muted">Recommendation by Cedant</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
          <div className="form-group">
          <label for="exampleInputEmail1">Negative Indicator</label>
          <input type="text" className="form-control" id="col102" name="col102" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Decision</label>
              <select id="col52" name="col52" className="form-control">
                <option value="0">-SELECT-</option>
                <option value="ADMIT">ADMIT</option>
                <option value="MR NOT ON RISK">MR NOT ON RISK</option>
                <option value="PENDING">PENDING</option>
                <option value="REJECT">REJECT</option>
                <option value="REPUDIATE">REPUDIATE</option>
                <option value="NOT REINSURED WITH MR">NOT REINSURED WITH MR</option>
                <option value="REQUIREMENT">REQUIREMENT</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Decision</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
          <div className="form-group">
          <label for="exampleInputEmail1">Reason for Repudation</label>
          <input type="text" className="form-control" id="col103" name="col103" />
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>
<div className="col-12">
  <div className="card">
  <div className="card-header bg-gradient-cyan">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">Check List</div>
      </div>
    </div>
    <div className="card-body">
      <div className="checklist" id="activeChkList">

      </div>
        
    </div>
  </div>

</div>
{/* Newl2121 */}
<div className="col-12">
  <div className="card">
    <div className="card-header bg-gradient-cyan">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">Key Dates</div>
      </div>
    </div>
    <div className="row">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of first referral to MR</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input onBlur={()=>this.CheckEndToEndDur()} type="text" id="col53" name="col53" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Date of first referral to MR</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of First revert by MRIB</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col54" name="col54" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Date of First revert by MRIB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of receipt of requirement (1)</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col55" name="col55" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of receipt of requirement (1)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of revert by MRIB</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col56" name="col56" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of revert by MRIB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of receipt of requirement (2)</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col57" name="col57" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of receipt of requirement (2)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of revert by MRIB</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col58" name="col58" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of revert by MRIB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of receipt of requirement (3)</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col59" name="col59" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of receipt of requirement (3)</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of revert by MRIB</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col60" name="col60" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              
              <small id="emailHelp" className="form-text text-muted">Date of revert by MRIB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of receipt of final requirement </label><br></br>
              
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col61" name="col61" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of receipt of final requirement </small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of final decision by MRIB</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" onBlur={()=>this.CheckEndToEndDur()}  id="col62" name="col62" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of final decision by MRIB</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Date of referal to LAU</label><br></br>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col63" name="col63" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Date of referal to LAU</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">End to End TAT</label>
              <input readOnly="true" type="text" id="col64" name="col64" className="form-control"   placeholder="End to End TAT" />
              <small id="emailHelp" className="form-text text-muted">(Date 1st reported to MRIB till date of MRIB decision)</small> </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Name of the Claim assessor</label>
              <input readOnly="true" type="text" id="emp_user_name" name="emp_user_name" className="form-control" value={userObject[0].person_name}   placeholder="Name of the Claim assessor" />
              <small id="emailHelp" className="form-text text-muted">Name of the Claim assessor</small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Claim Status</label>
              <select id="status" name="status" className="form-control">
              <option value="0">-SELECT-</option>
              <option value="Closed">Closed</option>
                <option value="Approved">Pending</option>
                <option value="Pending Requirements">Pending Requirements</option>
                <option value="Rejected">Rejected</option>
              </select>
              <small id="emailHelp" className="form-text text-muted">Set Status for future referecen</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='col-12'>
  <div className="row">
    <div className='col-4'><div onClick={()=>this.saveGenerateWork()} className="btn btn-danger">Save & Generate WorkSheet</div></div>
    <div className='col-4'><div onClick={()=>this.saveClaim()} className="btn btn-warning">Save</div></div>
  </div>
</div>
 </form>
 <Claim_Work_Sheet workSheet={this.state.stWorkSheet}/>
</div>
        );
    }
}
export default New_Claim_Comp;