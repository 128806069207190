import React from 'react';
class Itr_Declaration extends React.Component{
    render(){
        return(
            <div id="it_declaration" class="row tab-pane">
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <h5><i class="fa fa-check"></i> I.T Declarations</h5>
    </div>
    <div class="row">
      <div class="card-body">
        <h5>Chapter 6 (Max Exemption Limit : ₹ 1,50,000)</h5>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">LIC</label>
              <input type="text" class="form-control"   placeholder="LIC..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">ULIP</label>
              <input type="text" class="form-control"   placeholder="ULIP..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">PPF</label>
              <input type="text" class="form-control"   placeholder="PPF..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-12">
  <div class="card">
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">GPF</label>
              <input type="text" class="form-control"   placeholder="GPF" />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">House Loan Payment</label>
              <input type="text" class="form-control"   placeholder="House Loan Payment..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Infrastructure Bond</label>
              <input type="text" class="form-control"   placeholder="Infrastructure Bond..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Mutual Fund</label>
              <input type="text" class="form-control"   placeholder="Mutual Fund..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Tuition Fees</label>
              <input type="text" class="form-control"   placeholder="Tuition Fees..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Fixed Deposit</label>
              <input type="text" class="form-control"   placeholder="Fixed Deposit..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">others</label>
              <input type="text" class="form-control"   placeholder="others..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Infrastructure Development</label>
              <input type="text" class="form-control"   placeholder="Infrastructure Development..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Sukanya Samriddhi Scheme</label>
              <input type="text" class="form-control"   placeholder="Sukanya Samriddhi Scheme..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Voluntary Provident Fund (VPF)</label>
              <input type="text" class="form-control"   placeholder="Voluntary Provident Fund (VPF)..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header ">
      <h5>Section 10</h5>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Education Allowance bills</label>
              <input type="text" class="form-control"   placeholder="Education Allowance bills..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">LTA Bill</label>
              <input type="text" class="form-control"   placeholder="LTA Bill..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Vehicle and Fuel Allowance bills</label>
              <input type="text" class="form-control"   placeholder="Vehicle and Fuel Allowance bills..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. Housing Loan Interest (Max Exemption Limit : ₹ 2,00,000) </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. 80DD - Medical treatment for handicapped dependent (Max Exemption Limit : ₹ 1,25,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Housing Loan Interest Amount u/s 24</label>
              <input type="text" class="form-control"   placeholder="Housing Loan Interest Amount u/s 24..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">Medical Treatment</label>
              <input type="text" class="form-control"   placeholder="Medical Treatment..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. 80DDB Med Exp incurred on treatment of employees or his dependent (Max Exemption Limit : ₹ 40,000) </div>
        <div class="col-lg-6 col-md-6 col-12"> 2.  80U - Income of Blind OR Physically Handicapped person (Max Exemption Limit : ₹ 1,25,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Depandant Handicapped Treatment</label>
              <input type="text" class="form-control"   placeholder="Depandant Handicapped Treatment..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">Income of Blind OR Physically Handicapped person</label>
              <input type="text" class="form-control"   placeholder="Income of Blind OR Physically Handicapped person..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. 80 E- Interest paid on Higher Education Loan </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. 80 EE - Additional Home Loan Interest Deduction (Max Exemption Limit : ₹ 50,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Interest Education Loan</label>
              <input type="text" class="form-control"   placeholder="Interest Education Loan..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">Additional Home Loan Interest Deduction (U/Sec 80EE)</label>
              <input type="text" class="form-control"   placeholder="Additional Home Loan Interest Deduction (U/Sec 80EE)..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. Section 16 Entertainment Allowance </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. Section 80D - Medical Insurance Self (Max Exemption Limit : ₹ 25,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Entertainment Allowance bill</label>
              <input type="text" class="form-control"   placeholder="Entertainment Allowance bill..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Health Insurance self</label>
              2.
              <input type="text" class="form-control"   placeholder="Health Insurance self..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. HRA Bills </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. National Pension Scheme (NPS) u/s 80CCD (Max Exemption Limit : ₹ 50,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">HRA Bill</label>
              <input type="text" class="form-control"   placeholder="HRA Bill..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">National Pension Scheme (NPS) u/s 80CCD</label>
              <input type="text" class="form-control"   placeholder="National Pension Scheme (NPS) u/s 80CCD..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12"> 1. Let out property Housing Loan Interest u/s 24 (Max Exemption Limit for Loss on Housing Loan Interest u/s 24 : ₹ 2,00,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Profit on Housing Loan Interest u/s 24 (Let out property)</label>
              <input type="text" class="form-control"   placeholder="Profit on Housing Loan Interest u/s 24 (Let out property)..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Loss on Housing Loan Interest u/s 24 (Let out property)</label>
              <input type="text" class="form-control"   placeholder="Loss on Housing Loan Interest u/s 24 (Let out property)" />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. 80 G - Donation </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. 80DDB Med Exp incurred on treatment of employees or his dependent if senior citizen (Max Exemption Limit : ₹ 1,00,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">80 G - Donation</label>
              <input type="text" class="form-control"   placeholder="80 G - Donation..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">Depandant Handicapped Treatment self</label>
              <input type="text" class="form-control"   placeholder="Depandant Handicapped Treatment self..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. 80TTA (Max Exemption Limit : ₹ 10,000) </div>
        <div class="col-lg-6 col-md-6 col-12"> 2. 80TTB (Max Exemption Limit : ₹ 50,000) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">Income from Other sources(80TTA)</label>
              <input type="text" class="form-control"   placeholder="Income from Other sources(80TTA)..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 2.
              <label for="exampleInputEmail1">Income from Other sources(80TTB)</label>
              <input type="text" class="form-control"   placeholder="Income from Other sources(80TTB)..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="card">
    <div class="card-header bg-gradient-cyan">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12"> 1. Section 80CCD(2) </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group"> 1.
              <label for="exampleInputEmail1">NPS Employer Bill</label>
              <input type="text" class="form-control"   placeholder="NPS Employer Bill..." />
              <small id="emailHelp" class="form-text text-muted">Your Declaration value : 0</small> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <div class="btn btn-primary">Save </div>
  
</div>

        );
    }
}
export default Itr_Declaration;